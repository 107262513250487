import React, { useState, useEffect } from 'react';
import styles from './MentionsLegales.module.scss';
import Markdown from 'markdown-to-jsx';
import PolitiqueDeConfidentialite from './PolitiqueDeConfidentialite.md';
export const MentionsLegales = () => {
	console.log(PolitiqueDeConfidentialite)

	const [privacyPolicy, setPrivacyPolicy] = useState("");
	useEffect(() => {
		fetch(`./${PolitiqueDeConfidentialite}`)
		.then(response => response.text())
		.then(setPrivacyPolicy)
	}, []);
	console.log(PolitiqueDeConfidentialite, privacyPolicy)

	return (
		<div className={styles.Container}>
			<h3>Mentions légales du site GOOD Vibes</h3>
			<div className={styles.Content}>
				<span className={styles.Section}>
					<h4>Editeur</h4>
					<p>
						Les sites et services GOOD Vibes sont édités par la SARL VISIGO,
						société au capital de 37.750€, inscrite au registre du commerce et
						des sociétés de PARIS sous le numéro 489 679 597, domiciliée 49 rue
						de Courcelles 75008 Paris.
					</p>
				</span>
				<span className={styles.Section}>
					<h4>Directrice de la publication</h4>
					<p>Madame Caroline de La Palme</p>
				</span>
				<span className={styles.Section}>
					<h4>Hébergeur</h4>
					<p>
						Les données personnelles sont stockées au sein de l'Union
						Européenne.<br />
						Les sites et services GOOD Vibes sont hébergés par : <br />- APPDRAG
						LIMITED, société ayant son siège social au 66 Fitzwilliam Square,
						Dublin 2 D02 AT27, IRLANDE. <br />
						Company Registration Number 576395 ; <br />
						- Netlify, Inc., société ayant son siège social au 2325 3rd Street,
						Suite 296, San Francisco, California 94107, USA. <br />
					</p>
				</span>
				<span className={styles.Section}>
					<h4>Contact</h4>
					<p>
						Téléphone : <a href="tel:+331 80 49 49 65">+33 (0)1 80 49 49 65</a>{' '}
						<br />
						Fax : <a href="tel:+331 80 49 49 52">+ 33 (0)1 80 49 49 52</a> <br />
						E-mail :{' '}
						<a href={`mailto:${process.env.REACT_APP_contact}`}>bonjour@goodvibes.news</a>
					</p>
				</span>
				<br/>
			<Markdown>{privacyPolicy}</Markdown>
			</div>

		</div>
	);
};
