import React, { useState, useEffect } from 'react';
import styles from './CaseStudies.module.scss';
import { ClientInfo, Bubble, CaseStudiesData } from 'components';

const csl = (...classes) => classes.filter(Boolean).join(' ');
// export const CASE_STUDY_STYLES = [
//   styles.ActiveCaseStudy,
//   styles.InactiveCaseStudy,
// ];

export const CaseStudies = ({ children }) => {
	const [activeStudy, setActiveStudy] = useState(0);
	const [caseStudy, setCaseStudy] = useState(CaseStudiesData[activeStudy]);

	const [activeBubble, setActiveBubble] = useState(1);

	useEffect(() => {
		setCaseStudy(CaseStudiesData[activeStudy]);
	}, [activeStudy]);

	return (
		<section>
			<div className={styles.Companies}>
				{
					CaseStudiesData &&
						CaseStudiesData.map((caseStudy, index) => (
							<ClientInfo
								key={caseStudy.companyName}
								// Apply .selected class to the selected element
								className={csl(activeStudy === index ? styles.selected : null)}
								onClick={() => setActiveStudy(index)}>
								{caseStudy}
							</ClientInfo>
						)) // End of .map
				}
			</div>
			<div className={styles.AllBubbles}>
				<Bubble
					heading={'Enjeux'}
					content={caseStudy.enjeux}
					className={csl(activeBubble === 1 ? styles.selected : null)}
					onClick={() => setActiveBubble(1)}
				/>
				<Bubble
					heading={'Réponse GOOD Vibes'}
					content={caseStudy.reponseGv}
					className={csl(activeBubble === 2 ? styles.selected : null)}
					onClick={() => setActiveBubble(2)}
				/>
				<Bubble
					heading={'La voix du client'}
					content={`<i>${caseStudy.voixDuClient}</i>`}
					{...caseStudy}
					className={csl(activeBubble === 3 ? styles.selected : null)}
					onClick={() => setActiveBubble(3)}
				/>
			</div>
		</section>
	);
};
