import React, { useRef } from 'react';
import { Stream } from '@cloudflare/stream-react';
import { TryNowForm } from 'components';
import styles from './Hero.module.scss';
import Lottie from 'react-lottie-player';
import * as lottieBlob from 'static/anim/66859-blobs.json';
import logoAnim from 'static/img/logo-gv.png';
import useIsVisible from 'utils/useIsVisible';
import Typical from 'react-typical';
import { posthog } from 'posthog-js';
import poster from 'static/img/video-posters/APPROCHE-SYSTEMIQUE_LQ.jpg';
const disableAnimation = true;

// const csl = (...classes) => classes.filter(Boolean).join(" ");

const enterpriseAdjectives = ['Innovantes', 'Durables', 'Fortes'];
const employeesAdjectives = ['Créatifs', 'Conscients', 'Engagés'];

const typingInterval = 2500; // ms to wait in between words

const video = {
	date: '2023-06-14',
	titre: `Approche Systémique Longue`,
	cdn_id: '1805c1456ba1d0cd453dbc6fb0a96540',
	poster: window.location.origin + poster,
	// 'https://customer-yark6h873aaog1bz.cloudflarestream.com/1805c1456ba1d0cd453dbc6fb0a96540/thumbnails/thumbnail.jpg?time=2s&height=1080',
};
const handleVideoEvent = (event) => {
	console.log('Video event:', event.type, video);
	posthog.capture('VIDEO_' + event.type.toUpperCase(), { ...video });
};

export const Hero = () => {
	const animationRef = useRef();
	const animationIsVisible = useIsVisible(animationRef, false);
	console.log(video.poster);
	return (
		<>
			<div className={styles.HeroParent}>
				<div className={styles.TextAndLogo}>
					<div className={styles.TextContainer}>
						<span className={styles.TextLeft}>Des organisations</span>

						<span className={styles.TextRight}>
							<span className={styles.Plus}>plus </span>
							<Typical
								loop={Infinity}
								wrapper="span"
								className={styles.HeroWordsOrganisations}
								steps={enterpriseAdjectives.reduce(
									(arr, option) => [...arr, option, typingInterval],
									[]
								)}
							/>
						</span>
						<span className={styles.GraceA}>grâce à</span>
						<span className={styles.TextLeft}>des collaborateurs</span>
						<span className={styles.TextRight}>
							<span className={styles.Plus}>plus </span>
							<Typical
								loop={Infinity}
								wrapper="span"
								className={styles.HeroWordsCollaborateurs}
								steps={employeesAdjectives.reduce(
									(arr, option) => [...arr, option, typingInterval],
									[]
								)}
							/>
						</span>
					</div>

					<div className={styles.LogoAnimContainer}>
						{disableAnimation ? (
							<img
								src={logoAnim}
								alt="Good-vibes-animated-logo"
								className={styles.LogoAnim}
							/>
						) : (
							<div ref={animationRef} className={styles.MainAnim}>
								{animationIsVisible && (
									<Lottie
										loop
										animationData={lottieBlob}
										play
										style={{ width: 250, height: 250 }}
									/>
								)}
							</div>
						)}
					</div>
				</div>
				<div
					className={[
						styles.VideoPlayerContainer,
						styles.HeroVideoContainer,
					].join(' ')}>
					<Stream
						style={{ maxWidth: '600px' }}
						controls
						src={video.cdn_id}
						onPlay={handleVideoEvent}
						onPause={handleVideoEvent}
						onEnded={handleVideoEvent}
						poster={video.poster}></Stream>
				</div>
				<div className={styles.FormContainer}>
					{/* If we want to remove all form fields and only keep the button / link to form page, uncomment the following line and comment the rest of the div */}
					{/* <Link to={process.env.REACT_APP_path_try_now_form} target="_blank" rel="noopener noreferrer"><BtnCta content="Recevez un épisode gratuit par sms" variant="primary"/></Link> */}
					<div className={styles.DescriptionText}>
						{/* todo: replace with translation key and use the same on Form page */}
						Recevez un épisode gratuit, par SMS :
					</div>
					<span className={styles.Form}>
						<TryNowForm />
					</span>
					<div className={styles.DescriptionText}>
						GOOD Vibes s'engage à ne jamais transmettre vos informations
						personnelles à des tiers.
					</div>
				</div>
			</div>
		</>
	);
};
