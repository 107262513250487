import React from 'react';
import styles from './Presentation.module.scss';
import { Stream } from '@cloudflare/stream-react';
import { posthog } from 'posthog-js';
// import Markdown from 'markdown-to-jsx';

// const description =
// `Notre produit est vraiment super. Elle est essentiel pour plein de choses :
// <ul>
// - Nourrir votre équipe
// - Innover
// - Etre pleinement dans la performance (au lieu de l'excellence)
// </ul>`

const video = {
	date: '2023-06-14',
	titre: `Approche Systémique Longue`,
	cdn_id: '1805c1456ba1d0cd453dbc6fb0a96540',
	poster:
		'https://customer-yark6h873aaog1bz.cloudflarestream.com/1805c1456ba1d0cd453dbc6fb0a96540/thumbnails/thumbnail.jpg?time=2s&height=1080',
};
const handleVideoEvent = (event) => {
	console.log('Video event:', event.type, video);
	posthog.capture('VIDEO_' + event.type.toUpperCase(), { ...video });
};

const handleVideoProgress = (update) => {};
export const Presentation = () => {
	return (
		<section className={styles.Container}>
			<h3>C'est quoi, GOOD Vibes ?</h3>
			{/* Markdown won't compile if html elements are inside of it, unless you wrap it all in backticks, this seems to be a known issue
			https://github.com/mathieudutour/medium-to-own-blog/commit/0c5eb3bad8a747e7a2c03837e85d65b220bb50f2 */}
			{/* <iframe title="presentation-gv" src="https://docs.google.com/presentation/d/e/2PACX-1vS9O5_BHZ91VhbUVJJ4-dgpEIop9ULmqn5Y--VPZMxITTRymePes_keSz9dLwXe-t1MmT1iyjbRUW9E/embed?start=false&loop=false&delayms=3000" frameBorder="0" width="1133" height="586"allowFullScreen="true" mozallowfullscreen="true"="true" webkitallowfullscreen="true"="true"></iframe> */}
			{/* <iframe Switch to Approche systémique
				title="screencast" type="text/javascript" 
				src='https://cdnapisec.kaltura.com/p/2508431/embedPlaykitJs/uiconf_id/48733743?iframeembed=true&entry_id=1_6dwgnttk' 
				style={{width: "100%", height: "80vh", margin: "0 auto"}} 
				allowfullscreen webkitallowfullscreen="true" mozallowfullscreen="true" 
				allow="autoplay *; fullscreen *; encrypted-media *" 
				frameBorder="0">
			</iframe> */}
			<div className={styles.VideoPlayerContainer}>
				<Stream
					controls
					src={video.cdn_id}
					poster={video.poster}
					onPlay={handleVideoEvent}
					onPause={handleVideoEvent}
					onEnded={handleVideoEvent}
					onProgress={handleVideoProgress}
					onTimeUpdate={handleVideoProgress}></Stream>
			</div>
			{/* <Markdown className={styles.Content}>
				{description}
			</Markdown> */}
		</section>
	);
};
