import React, { useState } from 'react';
import styles from './FeatureSelector.module.scss';
import Markdown from 'markdown-to-jsx';
const SmartAnimate = React.lazy(() => import('utils/SmartAnimate'));

// const csl = (...classes) => classes.filter(Boolean).join(' ');

export const FeatureSelector = ({ title, paragraph, listElements }) => {
	const [activeTopic, setActiveTopic] = useState(0);

	const setNextTopic = () => {
		console.log("setNextTopic called!")
		setActiveTopic(
			activeTopic === listElements.length - 1 ? 0 : activeTopic + 1
			);
	}

	const listMapper = (feature, idx) => (
		<li
			key={feature.title}
			className={activeTopic === idx ? styles.selected : styles.notSelected}
			onMouseOver={() => setActiveTopic(idx)}>
			<h6 className={styles.FeatureTitle}>{feature.title}</h6>
		</li>
	);

	// const insertAnim = (c) => <img className={c} order={2} src={listElements[activeTopic].anim} alt="" />
	const insertAnim = (c) => (
		<div className={[styles.Anim, c].join(' ')}>
			{/* BACKGROUND */}
			<SmartAnimate
				className={styles.Stacked}
				play
				loop
				filepath={'/static/anim/FOND_TACHES.json'}
			/>
			{/* FEATURE */}
			<SmartAnimate
				className={styles.Stacked}
				play
				callback={setNextTopic}
				style={{ zIndex: 5 }}
				filepath={listElements[activeTopic].anim}
			/>
		</div>
	);
	return (
		<div className={styles.Container}>
			<ul>
				<h5 className={styles.SubsectionTitle} order={1}>{title}</h5>
				<section order={3} className={styles.Paragraph}>
					<Markdown>
						{paragraph}
					</Markdown>
				</section>
				{insertAnim(styles.Mobile)}
				{listElements.map(listMapper)}
			</ul>
			{insertAnim(styles.Desktop)}
		</div>
	);
};
