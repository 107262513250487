import React from 'react'; 
import styles from './NotFound.module.scss';
import { Link } from 'react-router-dom';
import { TextOnLogoShape } from 'components/TextOnLogoShape';

export const NotFound = () => {

	return (
		<section className={styles.NotFoundParent}>
           <TextOnLogoShape logoText="Page non trouvée"/>
           <p>Il semblerait que la page demandée n'existe pas...</p>
           <Link to='/'>Retourner à l'accueil</Link>
		</section>
	)
}
