import React, { useState, useEffect } from 'react';
import styles from './Questionnaire.module.scss';
import Markdown from 'markdown-to-jsx';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';
import { useAnalytics } from 'utils/useAnalytics.js';
import { posthog } from 'posthog-js';

const description = `###C'est fait !
#Merci, nous sommes heureux de pouvoir échanger avec vous très bientôt !`;

const almost_there = `# Une derniere étape !						
 Prenez 10 minutes pour bénéficier d'une analyse personnalisée de vos résulats avec un expert de GOOD Vibes.`;

const defaultFormID =
	'1FAIpQLSc_J97l6R3W0BFdd_qekzq68MecCWbivT5qEt9Vh-xyWV9H_Q';

export const Questionnaire = ({ formID = defaultFormID }) => {
	const formUrl = `https://docs.google.com/forms/d/e/${formID}/viewform?embedded=true`;
	const [calendlyEvent, setCalendlyEvent] = useState(null);
	const [formState, setFormState] = useState(null);

	console.log('loading', formUrl);
	const analytics = useAnalytics();
	useEffect(() => {
		console.log('Calendly says:', calendlyEvent);
		if (!calendlyEvent) return;
		analytics.capture(analytics.events.questionnaire.rdv_pris);
		posthog.capture(analytics.events.questionnaire.rdv_pris.name);
	}, [analytics, calendlyEvent]);

	useEffect(() => {
		if (formState === 'loaded') {
			analytics.capture(analytics.events.questionnaire.clicked);
			posthog.capture(analytics.events.questionnaire.clicked.name);
		}
		if (formState === 'submitted') {
			analytics.capture(analytics.events.questionnaire.submitted);
			posthog.capture(analytics.events.questionnaire.submitted.name);
		}
	}, [analytics, formState]);

	// The Google Form will trigger an "onLoad" on first load
	// And a second onLoad on form submission.
	// Untested but hypothesized: onLoad will likely trigger if the form has multiple pages, so you'd need to implement some sort of load counter
	function formLoad() {
		console.log('Form onload triggered, form state is', formState);
		switch (formState) {
			case null:
				setFormState('loaded');
				break;
			case 'loaded':
				setFormState('submitted');
				break;
			default:
		}
	}

	return (
		<section className={styles.QuestionnaireParent}>
			{calendlyEvent ? (
				<Markdown className={styles.Content}>{description}</Markdown>
			) : formState !== 'submitted' ? (
				<>
					{/* <Markdown className={styles.Content}>
						{description}
					</Markdown> */}
					<iframe
						title="GV Questionnaire"
						src={formUrl}
						onLoad={formLoad}
						width="100%"
						height="100%"
						frameBorder="0"
						marginHeight="0"
						marginWidth="0">
						Loading…
					</iframe>
				</>
			) : (
				<>
					<Markdown className={styles.Content}>{almost_there}</Markdown>
					<InlineWidget url="https://calendly.com/parlons-de-goodvibes/introduction?hide_gdpr_banner=1&background_color=none" />
				</>
			)}
			<CalendlyEventListener
				// onDateAndTimeSelected={function noRefCheck(){}}
				onEventScheduled={setCalendlyEvent}
				// onEventTypeViewed={function noRefCheck(){}}
				// onProfilePageViewed={function noRefCheck(){}}
			></CalendlyEventListener>

			{/* Markdown won't compile if html elements are inside of it, unless you wrap it all in backticks, this seems to be a known issue
			https://github.com/mathieudutour/medium-to-own-blog/commit/0c5eb3bad8a747e7a2c03837e85d65b220bb50f2 */}
		</section>
	);
};
