import React, { useState } from 'react';
import { BtnCta } from 'components';
import styles from './NavBar.module.scss';
import logo from 'static/img/logo.svg';
import burgerMenu from 'static/img/burger-menu.svg';
import { Link } from 'react-router-dom';
import burgerMenuOpen from 'static/img/burger-menu-open.svg';

export const NavBar = () => {
	const [mobileMenuVisible, setMobileMenuVisible] = useState();

	const links = (
		<>
			<Link to={process.env.REACT_APP_path_culture} className={styles.NavLink}>
				Culture
			</Link>
			<Link to={process.env.REACT_APP_path_episodes} className={styles.NavLink}>
				Episodes
			</Link>
			<Link to={process.env.REACT_APP_path_webinars} className={styles.NavLink}>
				Webinars
			</Link>
			<Link
				to={process.env.REACT_APP_path_rendez_vous}
				className={styles.NavLink}>
				Prendre RDV
			</Link>
			<a
				href={`mailto:${process.env.REACT_APP_contact}`}
				className={styles.NavLink}>
				Contact
			</a>
			<a
				href={process.env.REACT_APP_path_blog}
				target="_blank"
				rel="noopener noreferrer"
				className={styles.NavLink}>
				Blog
			</a>{' '}
			{!mobileMenuVisible && (
				<a
					href={process.env.REACT_APP_path_try_now_form}
					className={styles.NavLink}>
					<BtnCta content="Essai gratuit" variant="primary" className={styles.NavCta}/>
				</a>
			)}
			{mobileMenuVisible && (
				<Link
					to={process.env.REACT_APP_path_try_now_form}
					className={styles.NavLink}>
					Essai gratuit
				</Link>
			)}
		</>
	);
	return (
		<span
			className={[
				styles.NavBackground,
				mobileMenuVisible ? styles.MobileMenuOpen : '',
			].join(' ')}>
			<nav className={styles.NavBar}>
				<Link to="/" className={styles.Logo}>
					<img src={logo} alt="GOOD-Vibes-logo" />
				</Link>
				<div className={styles.DesktopNavLinks}>{links}</div>
                <div
                    onClick={() => setMobileMenuVisible(false)}
                    className={[
                        styles.MobileNavLinks,
                        mobileMenuVisible ? styles.open : '',
                    ].join(' ')}>
                    {links}
                </div>
				<img
					className={styles.HamburgerMenu}
					src={mobileMenuVisible ? burgerMenuOpen : burgerMenu}
					alt="menu"
					style={mobileMenuVisible ? { transform: 'rotateZ(540deg)' } : {}}
					onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
				/>
			</nav>
		</span>
	);
};
