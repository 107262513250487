import {useRef, useState, useEffect} from 'react'


export const StickyHeader = ({ children, sticky=false, css, className, marginTopProp="0px", ...rest }) => {
  const [isSticky, setIsSticky] = useState(false)
  const ref = useRef()
  
  // mount 
  useEffect(()=>{
    const cachedRef = ref.current,
          observer = new IntersectionObserver(
            ([e]) => {
              let evalSticky = e.intersectionRatio < 1;
              setIsSticky(evalSticky);
              document.body.classList[!evalSticky ? "add" : "remove"]("darkenTop")
            },
            {
              rootMargin: '-1px 0px 0px 0px',
              threshold: [0,1]
            }
          )

    observer.observe(cachedRef)
    
    // unmount
    return function(){
      observer.unobserve(cachedRef)
    }
  }, [])

  return (
    <>
    {/* 
    First div is a sentinel for detecting visibility
    0 padding property is to avoid this 'invisible' object getting affected by child padding settings of a parent container.
    */}
    <div ref={ref} style={{height:"1px", padding: "0"}} ></div> 
    <div style={{ padding: "0", marginTop: marginTopProp }} className={["will-stick", isSticky ? "stuck" : ""].join(" ")}></div>
    <header className={className} style={{ top: "-1px"}} {...rest}>
      {children}
    </header>
    </>
  )
}