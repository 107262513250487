import React, { useEffect, useState } from 'react';
import { Stream } from '@cloudflare/stream-react';
import styles from './Webinars.module.scss';
import Markdown from 'markdown-to-jsx';
import { smoothScrollTo } from './../utils/utilityFunctions';

// IMAGES
import logoAnim from 'static/img/logo-gv.png';
import playButton from 'static/img/play-button.svg';
import WB230415 from 'static/img/video-posters/WB230415FR.png';
import WB221214 from 'static/img/video-posters/WB221214FR.jpeg';
import poster from 'static/img/video-posters/webinar-poster.png';
import { posthog } from 'posthog-js';

const webinarsList = [
	{
		GVID: 'WB230415',
		date: '2023-04-15',
		titre: `La culture d'entreprise : Celle qui transporte et celle qui détruit...`,
		short_description: `La culture, le climat, l’énergie des collaborateurs…
			tout ce qui est invisible a pourtant des effets bien visibles.
			Et si la culture agissait directement sur l’engagement des collaborateurs ?`,
		long_description: `Celle qui transporte et celle qui détruit…
			La culture agit-elle comme un ICEBERG dans l’entreprise ?
			
			« La culture toxique a 10 fois plus d’impact
			sur le départ des collaborateurs que le niveau de rémunération »

			« Tout se joue au mental ! » disent les sportifs de haut niveau.
			Il semble que dans les entreprises aussi !
			 
			La culture, le climat, l’énergie des collaborateurs…
			tout ce qui est invisible a pourtant des effets bien visibles.
			Et si la culture agissait directement sur l’engagement des collaborateurs ?`,
		hook: 'La reconnaissance, un acte gratuit qui apporte beaucoup.',
		speaker_description: 'Avec Caroline de La Palme, Fondatrice, Visigo',
		episode_opengraph_thumbnail: WB230415,
		cdn_id: 'b0a7f4e0281cd81fe596867673c61e33',
		poster,
	},
	{
		GVID: 'WB221214',
		date: '2022-12-14',
		titre: `Transformation des entreprises`,
		short_description: `L’accélération du business, la digitalisation et l’absence de visibilité a bousculé notre quotidien professionnel et exige toujours plus de nous et de nos organisations de travail.`,
		long_description: `Nous vous proposons de partager les regards croisés de Betsy Parayil-Pezard et Caroline de La Palme. Betsy Parayil-Pezard, en tant que co-fondatrice d’une école de coaching vous éclairera sur les postures vitales à adopter en entreprise 		Caroline de La Palme, forte de son expérience de GOOD Vibes, sur les approches collectives pour agir sur l’écologie de nos organisations`,
		hook: 'Comment faire converger l’approche individuelle et collective ?',
		speaker_description:
			'Avec Caroline de La Palme, fondatrice Visigo et Betsy Parayil-Pezard, Co-fondatrice de Connection leadership et The Mountain',
		episode_opengraph_thumbnail: WB221214,
		cdn_id: 'ec949b3251f9262175b240a48df1f350',
		poster,
	},
];

const webinar_intro = `Quelle culture développer dans mon entreprise ?

Comment engager mes équipes ?

Quels sont les ingrédients du Futur Of Work?

Quel climat cultiver ?

Comment faire grandir mes talents ?

Si vous vous posez ces questions, vous trouverez peut-être ici des réponses !

Disponible pour vous, nos webinars, podcasts etc, sont là pour partager avec vous les lumières de nos invités.
	
À consommer, sans limites 🙂`;

export const WebinarsPage = () => {
	return (
		<section className={styles.Section}>
			<h1>Nos Webinars</h1>
			<div className={styles.Header}>
				<img src={logoAnim} alt="GOOD-Vibes-logo" className={styles.Logo} />
				<span className={styles.TitleContainer} id="webinar-title">
					<Markdown className={styles.Subtitle}>{webinar_intro}</Markdown>
				</span>
			</div>

			<PlayerAndWebinars />

			<legend>Toute diffusion et copie sans autorisation est interdite.</legend>
		</section>
	);
};

export const PlayerAndWebinars = () => {
	// const makePosterUrl = (v) =>
	// 	`https://customer-yark6h873aaog1bz.cloudflarestream.com/${v.cdn_id}/thumbnails/thumbnail.jpg?time=37s&height=600`;
	const [selectedVideo, setSelectedVideo] = useState(0);
	const { titre, cdn_id, date, poster, ...video } = webinarsList[selectedVideo];
	const poster_URL = window.location.origin + poster;
	useEffect(() => {
		console.log(selectedVideo, video);
	}, [selectedVideo, video]);

	const handleVideoEvent = (event) => {
		console.log('Video event:', event.type, video);
		posthog.capture('VIDEO_' + event.type.toUpperCase(), { ...video });
	};

	return (
		<div className={styles.PlayerAndEpisodes}>
			<div className={styles.VideoPlayerContainer}>
				<h2 data-poster={poster_URL} className={styles.VideoName}>
					{titre}
				</h2>
				<Stream
					controls
					src={cdn_id}
					poster={poster_URL}
					onPlay={handleVideoEvent}
					onPause={handleVideoEvent}
					onEnded={handleVideoEvent}></Stream>
			</div>
			{/* <Markdown>{webinarsList[selectedVideo].long_description}</Markdown> */}
			<div className={styles.EpisodesContainer}>
				{webinarsList.map((episode, i) => {
					return (
						<div
							key={episode.cdn_id}
							className={[
								styles.EmbeddedEpisode,
								selectedVideo === i ? styles.Selected : '',
							].join(' ')}
							onClick={() => {
								console.log('CLICKED');
								setSelectedVideo(i);
								smoothScrollTo('webinar-title');
							}}>
							<span className={styles.ThumbnailContainer}>
								<img
									className={styles.Thumbnail}
									src={episode.episode_opengraph_thumbnail}
									alt={`${episode.titre} thumbnail`}
								/>
								<img
									className={styles.PlayButton}
									src={playButton}
									alt={'play-button'}
								/>
							</span>

							<span className={styles.VideoInfo}>
								<span className={styles.VideoTheme}>
									{new Date(episode.date).toDateString()}
								</span>
								<span className={styles.VideoName}>{episode.titre}</span>
								<span className={styles.Interviewee}>
									{episode.speaker_description}
								</span>
								<span className={styles.VideoTheme}>
									{episode.short_description}
								</span>
							</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};
