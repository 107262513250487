import React from 'react';
import styles from './Footer.module.scss';
// import instagram from 'static/img/instagram.svg';
// import linkedin from 'static/img/linkedin.svg';
import whiteLogo from 'static/img/logo.svg';
import visigoLogo from 'static/img/visigo-logo.png';
import { TryNowForm } from 'components';
import { Link } from 'react-router-dom';
import G from './../static/img/svg-alphanum/G.svg';
import O from './../static/img/svg-alphanum/O.svg';

const csl = (...classes) => classes.filter(Boolean).join(' ');

export const Footer = () => {
	const currentYear = new Date().getFullYear();

	return (
		<footer className={styles.FooterParent}>
			<div className={styles.ReadyToGoFullContainer}>
				<span className={styles.Subtitle}>Accompagnez la transformation de vos équipes !</span>
				<span className={styles.ReadyToGoContainer}>
					<span className={styles.Ready}>READY</span>

					<span className={styles.ToGoContainer}>
						<span className={styles.To}>TO</span>
						<img src={G} alt="G" className={styles.ImageLetter} />
						<img src={O} alt="O" className={styles.ImageLetter} />
						<span className={styles.Question}>?</span>
					</span>
				</span>
			</div>
			<TryNowForm />
			<div className={styles.FooterLinksAndSocial}>
				<div className={styles.FooterLinks}>
					<Link
						to={process.env.REACT_APP_path_legal}
						target="_blank"
						rel="noopener noreferrer"
						className={styles.FooterLink}>
						Mentions légales
					</Link>
					<a
						href={process.env.REACT_APP_path_linkedin}
						target="_blank"
						rel="noopener noreferrer"
						className={styles.FooterLink}>
						LinkedIn
					</a>
					{/* <div className={styles.SocialLinks}> */}
					{/* TODO: uncomment instagram link whenever account is active */}
					{/* <a
							href={process.env.REACT_APP_path_instagram}
							target="_blank"
							rel="noopener noreferrer"
							className={styles.SocialLink}
							>
							<img src={instagram} alt="Instagram" />
							</a> 
							<a // eslint-disable-line
							href={process.env.REACT_APP_path_linkedin}
							target="_blank"
							rel="noopener noreferrer"
							className={styles.SocialLink}>
							<img src={linkedin} alt="LinkedIn" />
							</a> */}
					{/* </div> */}
				</div>
			</div>
			<div className={styles.LogosContainer}>
				<Link to="/" target="_blank" rel="noopener noreferrer">
					<img
						src={whiteLogo}
						alt="logo"
						className={csl(styles.Logo, styles.GoodVibes)}
					/>
				</Link>
				<p className={styles.LogoBy}>by</p>
				<a
					href="https://www.visigo.fr/"
					target="_blank"
					rel="noopener noreferrer">
					<img
						src={visigoLogo}
						alt="visigo"
						className={csl(styles.Logo, styles.Visigo)}
					/>
				</a>
			</div>
			<div className={styles.MadeWithLove}>Fait à Paris avec ♥️</div>
			<div className={styles.Copyright}>
				© {currentYear} GOOD Vibes. All rights reserved.
			</div>
		</footer>
	);
};
