import React from 'react';
import styles from './Decideurs.module.scss';
import Markdown from 'markdown-to-jsx';

const description = ""
// TODO: editorial team creates/validates description text + page h3 title
// `Les décideuses et décideurs : 
// <ul>
// - sont des gens
// - prennent des décisions
// - et font sûrement d'autres choses mais je sais pas quoi I'm not a stalker
// </ul>`

export const Decideurs = () => {

	return (
		<section className={styles.DecideursParent}>
			{/* <h3>GOOD Vibes</h3> */}
			{/* Markdown won't compile if html elements are inside of it, unless you wrap it all in backticks, this seems to be a known issue
			https://github.com/mathieudutour/medium-to-own-blog/commit/0c5eb3bad8a747e7a2c03837e85d65b220bb50f2 */}
			<iframe className={styles.Video} title="decideurs" type="text/javascript" src='https://cdnapisec.kaltura.com/p/2508431/embedPlaykitJs/uiconf_id/48733743?iframeembed=true&entry_id=1_qppjh1ek'allowFullScreen webkitallowfullscreen="true" mozallowfullscreen="true" allow="autoplay *; fullscreen *; encrypted-media *" frameBorder="0"></iframe>
			<Markdown className={styles.Content}>
				{description}
			</Markdown>

		</section>
	)
}
