const KEY_WORDS = [
	"GOOD Vibes",
	"émotions",
	"important",
	"dessin",
	"oublier",
	"surtout",
	"Excellente",
	"initiative",
	"contexte",
	"merci",
	"grand",
	"bravo",
	"rafraichissant",
	"bulles",
	"épisodes",
	"vidéos",
	"continuer",
	"continuez",
	"constructif",
	"aller",
	"loin",
	"concept",
	"format",
	"J'aime",
	"J'adore",
	"inspirant",
	"frais",
   "corriger",
   "mieux"
];


export const boldIfLongerThan = (word, minLength = 5) => {
   if ( word.length > minLength ) return `**${word}**`;
   else return word;
}

export const boldIfInList = (text, list) => {
   for (let KW of KEY_WORDS) {
      text = text.replace(KW, `**${KW}**`)
   }
}