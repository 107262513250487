import accorLogo from "static/img/logo-accor.png";
import airvanceLogo from "static/img/logo-airvance-group.png";
import cddLogo from "static/img/logo-caisse-des-depots.png";

const NBSP = "&nbsp;"

export const CaseStudiesData = [

{
    companyName: "Accor",
    companyQuote: "Garder le lien",
    companyLogo: accorLogo,
    enjeux: `Accor a mené pendant plusieurs années une __transformation profonde__ de son business model pour devenir **asset-light**. <br /> Cette mutation a nécessité un accompagnement considérable des talents du Groupe pour faire évoluer leur __mindset__.`,
    reponseGv: `Une ligne éditoriale __complètement adaptée aux besoins__ avec un épisode diffusé chaque lundi. <br /> Le petit bonus ? Des **épisodes personnalisés** portés par la direction Accor tout au long de la période du confinement pour soutenir le moral des équipes en télétravail ou au chômage partiel.`,
    voixDuClient: `«${NBSP}Nous sommes fiers d’avoir été parmi les premiers à avoir embarqué avec GOOD Vibes dès janvier 2020. Nous avons effectué un sondage en interne après 18 mois d’abonnement, et les répondants considèrent GOOD vibes comme inspirant (73%) et pédagogique (52%). <br />
    Pour 64% d’entre eux c’est __une respiration dans la semaine et une aide pour mieux travailler__ (38%). 40% échangent entre collègues autour de ces sujets${NBSP}»`,
    clientName: "Margaryta Kalancha",
    clientPosition: "Responsable Communication interne et partenaires Europe / Europe du Sud"
  },

{
  companyName: "Airvance Group",
  companyQuote: "Créer une culture commune",
  companyLogo: airvanceLogo,
  enjeux: `Né de la fusion de deux entreprises en février 2020, avec des équipes présentes dans __plusieurs pays en Europe__, travaillant à un rythme différent, AIRVANCE GROUP avait besoin de${NBSP}: <br/>
  → construire une __culture commune__ énergisante et __fédératrice__${NBSP};<br/>
  → donner des clés aux collaborateurs pour vivre cette transformation d’entreprise avec __sérénité et enthousiasme.__<br/>`,
  reponseGv: `Diffuser des contenus traitant de la transformation. Une vingtaine d’épisodes ont été diffusés auprès de __90 collaborateurs et collaboratrices,__ directement sur leur mobiles ou par e-mail, en français ou anglais selon leur pays. La souplesse de la plateforme GOOD Vibes a permis __d’ajuster la programmation des vidéos__, choisies au fil de l’eau par l’équipe AIRVANCE pour être au plus près du contexte quotidien des collaborateurs. __Un véritable programme «${NBSP}sur-mesure${NBSP}»__.`,
  voixDuClient: `«${NBSP}Après 5 mois d’abonnement, __nos managers ne veulent plus se passer de leur rendez-vous GOOD Vibes${NBSP}!__ Un format enthousiasmant, dynamique et inspirant pour faire le point et s’inspirer des bonnes pratiques managériales et de communication.${NBSP}»`,
  clientName: "Romane Genton",
  clientPosition: "Responsable communication interne"
},

{
    companyName: "Caisse des dépôts",
    companyQuote: "Un grand succès",
    companyLogo: cddLogo,
    enjeux: `Dans le contexte de crise sanitaire et de télétravail généralisé, ayant conduit la CDC à se réorganiser, cette dernière souhaitait${NBSP}:  <br/>
    <ul>
    <li>→ __«${NBSP}garder le lien${NBSP}»__ avec les collaborateurs${NBSP};</li>
    <li>→ maintenir une __bonne dynamique d’équipe__ malgré l’éloignement physique${NBSP};</li>
    <li>→ apporter du __contenu différencié__ sur une multitude de sujets (RH, développement personnel, etc.).</li>
    </ul>
    Avec un point de vigilance : proposer une __solution non-chronophage__ pour les équipes, déjà très exposées aux écrans. <br/>`,
    reponseGv: `Utiliser la __plateforme de GOOD Vibes__ pour envoyer les épisodes par e-mail. Les scores d’audience de la Caisse des Dépôts ont __très vite atteint des seuils assez élevés.__ 
    Mise en place d'un e-mail mensuel récapitulatif offrant une __2nde chance de visionnage__ des épisodes du mois, boostant les audiences au passage.`,
    voixDuClient: `«${NBSP}Ce fut très rapidement __un grand succès__${NBSP}: de nombreux retours positifs${NBSP}! Et ce, dans toutes les équipes, quels que soient les postes et les départements${NBSP}! Certains sujets sont plébiscités. <br />
    Je pense également que le court format (et responsive pour ceux qui consultent leurs mails depuis leur téléphone pro) a contribué au succès de GOOD Vibes.${NBSP}»`,
    clientName: "Carolle Verdeguer",
    clientPosition: "Responsable Communication Interne"
  },

]