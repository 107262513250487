import { useEffect } from 'react';

export const Chatbot = () => {
	useEffect(() => {
		window.chatwootSettings = {
			position: 'left',
			type: 'expanded_bubble',
			launcherTitle: 'Besoin de parler à un humain ?',
		};

		const chatwoot_script = document.createElement('script');
		chatwoot_script.src = `${process.env.REACT_APP_CHATWOOT_BASE_URL}/packs/js/sdk.js`;
		chatwoot_script.defer = true;
		chatwoot_script.async = true;
		document.body.appendChild(chatwoot_script);
		chatwoot_script.onload = function () {
			window.chatwootSDK.run({
				websiteToken: process.env.REACT_APP_CHATWOOT_TOKEN,
				baseUrl: process.env.REACT_APP_CHATWOOT_BASE_URL,
			});
		};

		return () => {
			// cleanup
		};
	}, []);

	return null;
};
