import React, { useRef } from 'react';
import useIsVisible from 'utils/useIsVisible';
import styles from './Figure.module.scss';

const csl = (...classes) => classes.filter(Boolean).join(' ');

export const Figure = ({
	variant = 'progress-line',
	className,
	children,
	percentage,
	legend,
	additionalLegend,
}) => {
	const figureRef = useRef();
	const figureInView = useIsVisible(figureRef, true);
	const progressInt = parseInt(children || percentage);

	return (
		<div
			ref={figureRef}
			className={csl(
				className,
				variant === 'progress-line' && styles.FigureParent
			)}>
			<div className={styles.PercentageText}>
				<span>{children || percentage}</span>
				<span className={styles.PercentSymbol}>%</span>
			</div>
			{/* use figureInView observable to trigger the 'real' size of the progress bar. 
        CSS animation properties means it will grow now that it is changed. */}
			{/* PROGRESS ELEMENT NOT SUPPORTED WELL IN MOBILE MZ. WENT BACK TO DIVS FOR NOW */}
			{/* <progress
				className={styles.PercentageProgress}
				min={0}
				max={100}
				value={figureInView ? progressInt : 0}
			/> */}
			<div className={styles.PercentageContainer}>
				<div
					style={figureInView ? { width: `${progressInt}%` } : null}
					className={styles.PercentageBar}></div>
			</div>

			<div className={styles.Legend}>{legend}</div>
			<div className={styles.AdditionalLegend}>{additionalLegend}</div>
		</div>
	);
};

// overflow clip / inlinestyle progressint% (1% size then transform)
