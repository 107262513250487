import React from 'react';
import ReactDOM, { render } from 'react-dom'; // eslint-disable-line
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import styles from 'blocks/Page.module.scss';

// COMPONENTS
import { Background } from 'components';
import { Footer, NavBar } from 'blocks';
import {
	CultureGV,
	Decideurs,
	Episodes,
	TryNow,
	MentionsLegales,
	NotFound,
	RDV,
	Questionnaire,
	ScreenCast,
	Unsubscribe,
	Presentation,
	Decouvrir,
	WhitePaper,
	Webinars,
} from 'pages';
import { ScrollToTop } from 'utils/ScrollToTop';

// FONTS
// Bernadette
import './static/fonts/Bernadette.eot';
import './static/fonts/Bernadette.svg';
import './static/fonts/Bernadette.ttf';
import './static/fonts/Bernadette.woff';
import './static/fonts/Bernadette.woff2';
// Bebas Neue Regular
import './static/fonts/BebasNeueRegular.eot';
import './static/fonts/BebasNeueRegular.otf';
import './static/fonts/BebasNeueRegular.svg';
import './static/fonts/BebasNeueRegular.ttf';
import './static/fonts/BebasNeueRegular.woff';
import './static/fonts/BebasNeueRegular.woff2';

import { posthog } from 'posthog-js';

if (process.env.NODE_ENV === 'production')
	posthog.init(process.env.REACT_APP_PH_TOKEN, {
		api_host: window.location.origin + '/quality',
	});

window.gtag('config', 'AW-11061873468');

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<ScrollToTop />
			<Background variant={'dark'} className={styles.BigSectionContainer}>
				<NavBar />
				<Routes>
					<Route path="/" element={<App />} />
					<Route
						exact
						path={process.env.REACT_APP_path_legal}
						element={<MentionsLegales />}
					/>
					<Route
						exact
						path={process.env.REACT_APP_path_episodes}
						element={<Episodes />}
					/>
					<Route
						exact
						path={process.env.REACT_APP_path_culture}
						element={<CultureGV />}
					/>
					<Route
						exact
						path={process.env.REACT_APP_path_try_now_form}
						element={<TryNow />}
					/>
					<Route exact path={'/livre-blanc'} element={<WhitePaper />} />
					<Route
						exact
						path={process.env.REACT_APP_path_screencast}
						element={<ScreenCast />}
					/>
					<Route
						exact
						path={process.env.REACT_APP_path_decideurs}
						element={<Decideurs />}
					/>
					<Route
						exact
						path={process.env.REACT_APP_path_rendez_vous}
						element={<RDV />}
					/>
					<Route
						exact
						path={process.env.REACT_APP_path_questionnaire}
						element={<Questionnaire />}
					/>
					<Route
						exact
						path={process.env.REACT_APP_path_questionnaire + '_test'}
						element={
							<Questionnaire formID="1FAIpQLSf1HEQtBVZJWe1U-sKDPSZfR_qK1vSabGpqa9HigYMu5r0oow" />
						}
					/>
					<Route
						exact
						path={process.env.REACT_APP_path_presentation}
						element={<Presentation />}
					/>
					<Route exact path={'/discover'} element={<Decouvrir />} />
					<Route path="/Unsubscribe" element={<Unsubscribe />} />
					<Route path="/webinars" element={<Webinars />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
				<Footer />
			</Background>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
