import React, { useState, useEffect } from 'react';
import styles from './RDV.module.scss';
import Markdown from 'markdown-to-jsx';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';
import { useAnalytics } from 'utils/useAnalytics.js';
import { posthog } from 'posthog-js';

const description = `###C'est fait !
#Merci, nous sommes heureux de pouvoir échanger avec vous très bientôt !`;
// event: "calendly.event_scheduled"
// payload:
// event:
// uri: "https://api.calendly.com/scheduled_events/2af35356-5abf-4a9e-b23d-812ca1d18404"
// [[Prototype]]: Object
// invitee:
// uri: "https://api.calendly.com/scheduled_events/2af35356-5abf-4a9e-b23d-812ca1d18404/invitees/fa12cf50-4f83-443b-8cca-b1c757bbda5a"

export const RDV = () => {
	const [calendlyEvent, setCalendlyEvent] = useState(null);
	const analytics = useAnalytics();
	useEffect(() => {
		analytics.capture(analytics.events.prendre_rdv.clicked);
		posthog.capture(analytics.events.prendre_rdv.clicked.name);
	}, [analytics]);
	useEffect(() => {
		if (!calendlyEvent) return;
		analytics.capture(analytics.events.prendre_rdv.submitted);
		posthog.capture(analytics.events.prendre_rdv.submitted.name);
	}, [analytics, calendlyEvent]);

	return (
		<section className={styles.RendezVousParent}>
			{calendlyEvent ? (
				<Markdown className={styles.Content}>{description}</Markdown>
			) : (
				<InlineWidget url="https://calendly.com/parlons-de-goodvibes/introduction?hide_gdpr_banner=1&background_color=none" />
			)}
			<CalendlyEventListener
				// onDateAndTimeSelected={function noRefCheck(){}}
				onEventScheduled={setCalendlyEvent}
				// onEventTypeViewed={function noRefCheck(){}}
				// onProfilePageViewed={function noRefCheck(){}}
			></CalendlyEventListener>

			{/* Markdown won't compile if html elements are inside of it, unless you wrap it all in backticks, this seems to be a known issue
			https://github.com/mathieudutour/medium-to-own-blog/commit/0c5eb3bad8a747e7a2c03837e85d65b220bb50f2 */}
		</section>
	);
};
