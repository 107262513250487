import React from 'react';
import styles from './CultureGV.module.scss';
import Markdown from 'markdown-to-jsx';

export const CultureGV = () => {
	return (
		<section className={styles.CultureGV}>
			<h3>Le why de GOOD Vibes</h3>
			{/* Markdown won't compile if html elements are inside of it, unless you wrap it all in backticks, this seems to be a known issue
			https://github.com/mathieudutour/medium-to-own-blog/commit/0c5eb3bad8a747e7a2c03837e85d65b220bb50f2 */}
			<Markdown className={styles.Content}>
				{`Certains ont fait l’expérience d’une vie professionnelle riche,
				énergisante, créative et qui leur a permis de se réaliser.  
				Ces expériences ont eu lieu dans des grandes entreprises comme dans des
				TPE. <br /><br />
				Donc c’est possible. 
				<br /><br />
				__Oui, il est possible de concevoir, partager, travailler, construire ensemble et créer des business durables__ sans
				déshumaniser nos fonctionnements ni réduire le collaborateur à la
				simple exécution de sa fonction. 
				<br /><br />
				La vision fondatrice de GOOD Vibes
				est que __l’excellence des organisations est intimement liée aux attitudes et comportements de ses collaborateurs.__ <br />
				<br /><br />
				Si on veut bâtir des entreprises plus agiles, plus innovantes, plus
				durables alors misons sur leur potentiel à faire face aux enjeux du
				XXIe siècle ! 
				<br /><br />
				__Pour cela, il faut aider les collaborateurs à :__
				<ul>
					<li>__comprendre pour ne pas subir le changement__</li>
					<li>__expérimenter pour grandir__</li>
					<li>__avancer pour se réaliser__</li>
					<li>__et prendre du plaisir dans sa vie professionnelle__</li>
					<br /><br />
				A travers une série de vidéos interactives, GOOD Vibes
				diffuse chaque semaine ses éclairages, conseils, inputs, tips, études
				scientifiques, sondages… au sein des organisations, inspire certains, pousse d’autres à changer leurs comportements et à donner
				le meilleur d’eux-mêmes.
				<br /><br />
				__GOOD Vibes agit comme un système nerveux.__ <br />
				__Il stimule, surprend, interroge, invite à tester des solutions et déploie votre culture commune grâce à son approche systémique.__`}
			</Markdown>
		</section>
	);
};
