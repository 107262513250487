import React from 'react';

export const Unsubscribe = () => {

	return (
		<section>
			<h1>Vous voulez nous quitter ?</h1>
			<p>
				Pour vous désabonner, veuillez cliquer sur l'un des liens que vous avez reçus et utiliser le lien "Se désabonner" situé en bas de la page.
			</p>
		</section>
	);
};
