import React, { useState, useEffect, useRef } from 'react';
import styles from './Forms.module.scss';
import { BtnCta, FadeIn } from 'components';
// import { useTranslation } from "react-i18next";
// import { useFetch } from "../../utils/fetchHook";
import { useForm } from 'react-hook-form';
import Lottie from 'react-lottie-player';

import * as ANIM_LOADING from 'static/anim/71460-squared-circles-red.json';
// import * as ANIM_ALERT from "static/anim/lf20_ZGnXlB.json";
import * as ANIM_SMS from 'static/anim/37923-sms-notification.json';
import * as ANIM_ERROR from 'static/anim/28815-errorwarning.json';
import { useAnalytics } from 'utils/useAnalytics.js';

const csl = (...classes) => classes.filter(Boolean).join(' ');

const options = {
	method: 'POST',
	headers: { 'Content-Type': 'application/json' },
};

/* If we want to hide the form and only display it onClick, just change displayForm to false in the line below (TryNowForm props) */
export const TryNowForm = ({
	displayForm = true,
	isVertical = false,
	API_BASE_URL = process.env.REACT_APP_API_URL_BASE,
}) => {
	// Toggle formState on click
	// false shows the CTA, true or 'shown' shows the form, 'submitted' is for after the form is submitted.
	const [formStatus, setFormStatus] = useState(displayForm);
	const [formIsReadyToSubmit, setFormIsReadyToSubmit] = useState(displayForm);
	const [formClicked, setFormClicked] = useState(false);
	useEffect(() => {
		setFormStatus(displayForm);
	}, [displayForm]);

	const analytics = useAnalytics();
	useEffect(() => {
		if (!formClicked) return; // Don't run until this has been clicked the first time
		analytics.capture(analytics.events.essai_gratuit.clicked);
		setFormClicked(true);
	}, [analytics, formClicked]);

	if (formStatus === 'submitted') window.navigator.vibrate(1000);

	// Setup form value handling
	// https://react-hook-form.com/api/useform
	const {
		register,
		handleSubmit,
		watch,
		setError,
		clearErrors,
		trigger,
		formState: { isValid, errors, isSubmitting },
	} = useForm({ mode: 'onBlur' });
	const watchTelephone = watch('tel_E164', false);
	const telTimeout = useRef();

	useEffect(() => {
		setFormIsReadyToSubmit(!isValid || !!errors.tel_E164);
	}, [errors, isValid]);

	useEffect(() => {
		// Wait until minimum telephone length before running validation via API
		if (watchTelephone && watchTelephone.length > 7) {
			clearTimeout(telTimeout.current);
			telTimeout.current = setTimeout(() => {
				validateTelNum(watchTelephone, API_BASE_URL)
					.then((message) => {
						clearErrors('tel_E164');
						trigger();
					})
					.catch((message) => {
						console.warn(message);
						setError('tel_E164', { message });
					});
			}, 500);
		} else {
			// Clear errors if they fix the number/reset the field
			clearErrors('tel_E164');
		}
	}, [
		API_BASE_URL,
		clearErrors,
		setError,
		telTimeout,
		trigger,
		watchTelephone,
	]);

	// Setup form submission
	const onSubmit = async (data, second) => {
		data.timezone = 'Europe / Paris';
		data.language = 'FR';

		console.log('submitting!');
		console.log(data, second);
		await fetch(`${API_BASE_URL}/websiteTryNow`, {
			...options,
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.status === 'KO') setFormStatus('error');
				if (response.status === 'OK') setFormStatus('success');
				analytics.capture(analytics.events.essai_gratuit.submitted);
				console.log(response);
				console.log('FORM IS: ', formStatus);
			})
			.catch(console.error);
	};

	// const { t } = useTranslation();

	return (
		<>
			{!formStatus && (
				<FadeIn delayInSeconds={2}>
					<BtnCta
						onClick={() => setFormStatus('shown')}
						variant="primary"
						content="Envoyez-moi un épisode !"
					/>
				</FadeIn>
			)}
			{(formStatus === true || formStatus === 'shown') && (
				<FadeIn>
					<form
						onSubmit={handleSubmit(onSubmit)}
						onFocus={() => setFormClicked(true)}
						name={'try-now-form'}
						data-name={'try-now-form'}
						className={styles.FormBody}>
						<div className={isVertical ? styles.VerticalInputs : styles.Inputs}>
							<input
								{...register('fName', {
									required:
										'Nous avons besoin de votre prénom pour nous adresser à vous.',
								})}
								className={csl(styles.FormField, errors.fName && styles.Error)}
								name={'fName'}
								data-name={'fName'}
								type={'text'}
								placeholder={'Marie'}
								required
							/>
							{errors.fName && (
								<div className={styles.ErrorMessage}>
									{errors.fName.message}
								</div>
							)}
							<input
								{...register('lName', {
									required:
										'Pour bien vous repérer dans notre base de données en cas de problème.',
								})}
								className={csl(styles.FormField, errors.lName && styles.Error)}
								name={'lName'}
								data-name={'lName'}
								type={'text'}
								placeholder={'Sklodowska'}
								required
							/>
							{errors.lName && (
								<div className={styles.ErrorMessage}>
									{errors.lName.message}
								</div>
							)}
							<input
								// ref={phoneFieldRef}
								{...register('tel_E164', {
									required:
										"Nous communiquons par SMS, nous avons donc besoin d'un numéro portable. Les téléphones fixes ou VoIP ne peuvent pas être utilisés.",
									validation: validateTelNum,
								})}
								className={csl(
									styles.FormField,
									styles.FormInputValid,
									errors.tel_E164 && styles.Error
								)}
								name={'tel_E164'}
								type={'tel'}
								data-name={'phone'}
								placeholder={'+33 6 12 34 56 78'}
								onFocus={(e) => {
									const input = e.target;
									// If input is not yet used, automatically add the default international prefix to help the user
									if (input.value !== '') return;
									const defaultPrefix = '+33';
									input.value = defaultPrefix;
									input.setSelectionRange(
										defaultPrefix.length,
										defaultPrefix.length
									);
								}}
								required
							/>
							{errors.tel_E164 && (
								<div className={styles.ErrorMessage}>
									{errors.tel_E164.message}
								</div>
							)}
							<input
								{...register('email', {
									required: 'Merci de nous indiquer une adresse e-mail !',
									minLength: {
										value: 5,
										message: 'Cette adresse est trop courte !',
									},
								})}
								className={csl(styles.FormField, errors.email && styles.Error)}
								name={'email'}
								type={'email'}
								data-name={'email'}
								placeholder={'marie@curie.fr'}
								required
							/>
							{errors.email && (
								<div className={styles.ErrorMessage}>
									{errors.email.message}
								</div>
							)}
							{/* {
                      errors && Object.values(errors).map(error => <FadeIn className={styles.ErrorMessage}><Lottie play loop animationData={ANIM_ALERT} style={{height: 50, width: 50, margin: "0 auto"}} />&nbsp;&nbsp;{error.message}</FadeIn>)
                    } */}
							{isSubmitting && (
								<Lottie
									loop
									play
									animationData={ANIM_LOADING}
									style={{ height: '50px', width: '50px' }}></Lottie>
							)}
							{/* <BtnCta type={"submit"} content="Submit" variant="primary" /> */}
						</div>
						<div>
							<input
								className={csl(
									styles.BtnCta,
									styles.SubmitButton,
									formIsReadyToSubmit ? styles.Disabled : ''
								)}
								type="submit"
								disabled={formIsReadyToSubmit}
								data-name="try-now-form-submit"
								value={'Envoyez-moi un épisode !'}
							/>
						</div>
					</form>
				</FadeIn>
			)}
			{(formStatus === 'success' || displayForm === 'submitted') && (
				<>
					<Lottie
						play
						loop
						animationData={ANIM_SMS}
						style={{ height: '100px' }}
					/>
					<p className={styles.DescriptionText}>
						Surveillez votre téléphone, les GOOD Vibes arrivent !
					</p>
				</>
			)}
			{(formStatus === 'error' || displayForm === 'error') && (
				<>
					<Lottie
						play
						loop
						animationData={ANIM_ERROR}
						style={{ height: '200px' }}
					/>
					<p>
						Oh non ! Une erreur est survenue. Notre équipe a été notifiée
						automatiquement. <br />
						Réitérez votre demande ultérieurement, ou{' '}
						<a href={`mailto:${process.env.REACT_APP_contact}`}>
							contactez-nous par e-mail.
						</a>
					</p>
				</>
			)}
		</>
	);
};

var messages = {
	landline: `Vous ne pouvez pas utiliser un numéro ☎️ fixe !`,
	voip: `Vous ne pouvez pas utiliser un numéro de téléphone VoIP !`,
	mobile: `Merci, votre numéro est 👍 📲  !`,
	invalid: `Ce numéro n'est pas valide 📵 !`,
	duplicate: `Vous avez déjà fait un essai gratuit, ou bien vous êtes déjà abonné·e 📵 !`,
};

function validateTelNum(phone_number, API_BASE_URL) {
	console.log(`Looking up Phone Number: ${phone_number} with country code.`);

	const validationUrl = `${API_BASE_URL}/validatePhoneNumber?telephone=${phone_number}`; // + "&CountryCode=" + countryCode_iso2;
	return new Promise((resolve, reject) => {
		fetch(validationUrl)
			.then((response) => response.json())
			.then(({ type }) => {
				switch (String(type)) {
					// Using "String() on the payload so I can case compare with undefined without throwing an error
					case 'mobile':
						// CASE VALID NUMBER
						resolve(messages[type]);
						break;
					case 'landline':
					case 'voip':
					case 'duplicate':
						reject(messages[type]);
						break;
					case 'null':
					case 'undefined':
					default:
						// CASE INVALID NUMBER
						// CASE LANDLINE
						reject(messages['invalid']);
						break;
				}
			})
			.catch(console.error);
	});
}
