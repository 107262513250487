import { posthog } from 'posthog-js';
export const useAnalytics = () => {
	window.ph = posthog;
	return {
		capture: (event) => {
			try {
				console.log('Capturing event:', event.name, window.gtag);
				window.gtag('event', 'conversion', {
					send_to: `${process.env.REACT_APP_GTAG_ID}/${event.id}`,
				});
				posthog.capture(event.name);
			} catch (e) {
				console.error('Could not capture analytics event:', e);
			}
		},
		events: {
			essai_gratuit: {
				clicked: { id: 'GmeyCO663rMYELyW25op', name: 'TRY_NOW_CLICKED' },
				submitted: { id: '6kgSCOisobIYELyW25op', name: 'TRY_NOW_SUBMITTED' },
			},
			prendre_rdv: {
				clicked: { id: 'YGX0COu63rMYELyW25op', name: 'RDV_CLICKED' },
				submitted: { id: '0HYbCN-63rMYELyW25op', name: 'RDV_SUBMITTED' },
			},
			livre_blanc: {
				clicked: { id: 'w_VXCOusobIYELyW25op', name: 'WHITEPAPER_CLICKED' },
				submitted: { id: 'Sk3ACNy63rMYELyW25op', name: 'WHITEPAPER_SUBMITTED' },
				downloaded: {
					id: 'P5HhCOK63rMYELyW25op',
					name: 'WHITEPAPER_DOWNLOADED',
				},
			},
			questionnaire: {
				clicked: { id: 'd8bvCOi63rMYELyW25op', name: 'QUESTIONNAIRE_CLICKED' },
				submitted: {
					id: 'jV85COW63rMYELyW25op',
					name: 'QUESTIONNAIRE_SUBMITTED',
				},
				rdv_pris: {
					id: 'oPwuCPWw37MYELyW25op',
					name: 'QUESTIONNAIRE_RDV_PRIS',
				},
			},
		},
	};
};

export default useAnalytics;
