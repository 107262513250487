import React from 'react';
import styles from './CaseStudies.module.scss';
import Markdown from 'markdown-to-jsx';

const csl = (...classes) => classes.filter(Boolean).join(' ');

export const ClientInfo = ({ className, onClick, children }) => {
	return (
		<div onClick={onClick} className={csl(className, styles.CompanyContainer)}>

      <img alt={children.companyName + 'Logo'} src={children.companyLogo} />

      <div
        className={csl(
          className, // Re-using className here so we can use the .selected class to toggle visibility of this element
          styles.Tagline
        )}>
        «&nbsp;{children.companyQuote}&nbsp;»
      </div>
      
		</div>
	);
};

export const Bubble = ({
	className,
	heading,
	content,
	clientName,
	onClick,
	clientPosition,
}) => {
	return (
		<div onClick={onClick} className={csl(className, styles.OneBubble)}>
			<div className={styles.BubbleContent}>
				<div className={styles.Header}>{heading}</div>
				<Markdown className={styles.Text}>{content}</Markdown>

				{clientName && (
					<div className={styles.ClientIdentity}>{clientName}</div>
				)}
				{clientPosition && (
					<div className={styles.ClientPosition}>{clientPosition}</div>
				)}
			</div>
		</div>
	);
};
