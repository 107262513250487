import React from 'react';
import { TryNowForm } from 'components/Form-TryNow';
import styles from 'pages/TryNow.module.scss';

export const TryNow = ({ isVertical }) => {
	return (
		<section className={styles.FormPageParent}>
			<h3>Essayez GOOD Vibes !</h3>
			<p className={styles.DescriptionText}>
				Recevez gratuitement un épisode de GOOD Vibes sur votre téléphone en
				remplissant le formulaire.
			</p>
			<TryNowForm isVertical />
			<p className={styles.DescriptionText}>
				GOOD Vibes s'engage à ne jamais transmettre vos informations
				personnelles à des tiers.
			</p>
		</section>
	);
};
