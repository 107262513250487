import { useState, useEffect } from "react";

const OPTIONS = {
  root: null,
  rootMargin: "0px 0px 0px 0px",
  threshold: 0,
};

/**
 * useIsVisible
 * @param { ref } elementRef 
 * - A useRef of the element you want to track the visibilty of. Warning: You cannot use the ref of an element you are showing conditionally.
 * If you want to show something conditionally (an animation component, for example, to avoid rendering when off-screen), you must use the ref of a parent DOM element 
 * and then decide to show the animation inside it using the returned value.
 * @param { Boolean } unobserveAfterFirstDisplay 
 * - Set to TRUE if you want the observable to only change once, the first time the element is visible in the viewport.
 * - Setting to FALSE means the observable will return a boolean that follows the element's visibility in real-time.
 * @returns { Boolean } isVisible - whether or not the ref'd element intersects with the viewport of the browser.
 */
const useIsVisible = (elementRef, unobserveAfterFirstDisplay = false) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const el = elementRef.current;

    if (el) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting)
          if (entry.isIntersecting && unobserveAfterFirstDisplay) observer.disconnect();
        });
      }, OPTIONS);
      observer.observe(el);
      return () => observer.disconnect()
    }
  }, [elementRef, unobserveAfterFirstDisplay]);
  return isVisible;
};

export default useIsVisible;