import React from 'react';
import styles from './Decouvrir.module.scss';
// import Markdown from 'markdown-to-jsx';
import { Link } from 'react-router-dom';
import play_button from 'static/img/play-button.svg';
import Markdown from 'markdown-to-jsx';

const intro = `
#__GOOD Vibes__ est le média positif dédié au Futur Of Work
## Clé en main, interactif et personnalisable, envoyez à vos collaborateurs les vidéos que vous avez choisies sur notre plateforme.
<br/>
<br/>
`;
export const Decouvrir = () => {
	return (
		<section className={styles.Container}>
			<Markdown className={styles.Intro}>{intro}</Markdown>
			{/* Markdown won't compile if html elements are inside of it, unless you wrap it all in backticks, this seems to be a known issue
			https://github.com/mathieudutour/medium-to-own-blog/commit/0c5eb3bad8a747e7a2c03837e85d65b220bb50f2 */}
			{/* <iframe className={styles.Video} title="decideurs" type="text/javascript" src='https://cdnapisec.kaltura.com/p/2508431/embedPlaykitJs/uiconf_id/48733743?iframeembed=true&entry_id=1_qppjh1ek'allowFullScreen webkitallowfullscreen="true" mozallowfullscreen="true" allow="autoplay *; fullscreen *; encrypted-media *" frameBorder="0"></iframe> */}
			<div class={styles.Grid}>
				<Link
					class={styles.Presentation}
					to={process.env.REACT_APP_path_presentation}>
					La Présentation de GV
					<img class={styles.icon} src={play_button} alt="play"></img>
				</Link>
				<Link class={styles.Catalogue} to={process.env.REACT_APP_path_episodes}>
					Notre catalogue
				</Link>
				<Link
					class={styles.Screencast}
					to={process.env.REACT_APP_path_screencast}>
					Découvrir notre plateforme
				</Link>
				<Link class={styles.RDV} to={process.env.REACT_APP_path_rendez_vous}>
					Prendre RDV
				</Link>
			</div>
		</section>
	);
};
