import React, { Suspense, useEffect, useState } from 'react';
import { BtnCta, Chatbot, TextOnLogoShape } from 'components';
import { Hero } from 'blocks';
import { QuotePopup } from 'components/Quotes.jsx';
import { ConsentModal } from 'components/ConsentModal.jsx';
import { posthog } from 'posthog-js';

const SectionInfo = React.lazy(() => import('./blocks/SectionInfo.jsx'));
const SectionProof = React.lazy(() => import('./blocks/SectionProof.jsx'));
const SectionSell = React.lazy(() => import('./blocks/SectionSell.jsx'));

const consentTitle = `Nous sommes conformes au RGPD !`;
const consentText = `Ici nous utilisons des cookies uniquement pour **améliorer notre site et mieux vous servir, sans aucune vente de données**.

Êtes-vous ok avec ça ?`;

function App() {
	const [userQuotes, setUserQuotes] = useState(false);
	const [userConsent, setUserConsent] = useState(null);

	useEffect(() => {
		const consentType = userConsent ? 'granted' : 'denied';
		const consentAction = userConsent === null ? 'default' : 'update';
		if (consentType && consentAction === 'update') {
			console.log('Logging PH event');
			posthog?.capture('USER_COOKIES_' + consentType?.toUpperCase());
		}

		console.log('userConsent is', userConsent, consentAction, {
			ad_storage: consentType,
			analytics_storage: consentType,
		});

		window.gtag('consent', consentAction, {
			ad_storage: consentType,
			analytics_storage: consentType,
		});
	}, [userConsent]);

	let isMobile =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			window.navigator.userAgent
		);

	useEffect(() => {
		async function getTestimonials() {
			fetch('./api/getTestimonials')
				.then((res) => res.json())
				.then(setUserQuotes);
		}
		getTestimonials();
	}, []);
	return (
		<>
			<Hero />
			<Suspense fallback={<TextOnLogoShape />}>
				<SectionInfo />
			</Suspense>
			<Suspense fallback={<TextOnLogoShape />}>
				<SectionProof />
			</Suspense>
			<Suspense fallback={<TextOnLogoShape />}>
				<SectionSell />
			</Suspense>
			{userConsent !== null ? (
				<Chatbot />
			) : (
				<ConsentModal title={consentTitle} text={consentText}>
					<BtnCta
						variant={'secondary'}
						content={'Non svp'}
						onClick={() => setUserConsent(false)}></BtnCta>
					<BtnCta
						content={'OK pour moi'}
						onClick={() => setUserConsent(true)}></BtnCta>
				</ConsentModal>
			)}
			{!isMobile && userQuotes ? <QuotePopup>{userQuotes}</QuotePopup> : null}
		</>
	);
}

export default App;
