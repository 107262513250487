import React from 'react';
import styles from './BtnCta.module.scss';

const csl = (...classes) => classes.filter(Boolean).join(" ");

export const BtnCta = ({ 
    variant = "primary",
    className,
    onClick,
    content,
}) => {
  return (
      <div
        onClick={onClick}
        className={csl(
          className,
          styles.BtnCta,
          variant === "secondary" && styles.Secondary,
          variant === "tertiary" && styles.Tertiary,
          variant === "transparent" && styles.Transparent,
          variant === "navbar" && styles.Navbar
          )}
      >
        {content}
      </div>
  );
};