import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './Quote.module.scss';
import {
	getIntegerFromRangeWithMethod,
	manageLineBreaks,
} from '../utils/utilityFunctions';
import Markdown from 'markdown-to-jsx';
import { boldIfLongerThan } from 'utils/KeywordToMd';
import useIsVisible from 'utils/useIsVisible';

const csl = (...classes) => classes.filter(Boolean).join(' ');
const loopThroughArray = (array, index) =>
	index === array?.length - 1 ? 0 : index + 1; // restart from beginning if we're are the last element

// This variable should be equal to the number of QuoteStyles defined in Quote.module.scss
const numberOfQuoteStyles = 14;
export const QUOTE_STYLES = [];

/**
 * This function takes a number as a parameter, corresponding to the amount of QuoteSyles we have.
 * It returns QUOTE_STYLES array completed with all styles
 */
function allQuoteStyles(num) {
	for (let i = 1; i < num; i++) {
		QUOTE_STYLES.push(styles[`QuoteStyle${i}`]);
	}
	return QUOTE_STYLES;
}

allQuoteStyles(numberOfQuoteStyles);

export const CORNER_STYLES = [styles.RightCorner, styles.LeftCorner];

export const Quote = ({
	info,
	variant = 1,
	corner = 1,
	className,
	style,
	nextQuote,
	animationEnabled,
	toggleSocialProof,
	quoteIndex,
	numQuotes,
}) => {
	let { firstName, feedback, episode_name } = info;

	feedback = manageLineBreaks(
		feedback
			.split(' ')
			.map((word) => boldIfLongerThan(word.trim(), 5))
			.join(' ')
	);
	return (
		<div
			className={csl(
				className,
				styles.Quote,
				animationEnabled && styles.QuoteAnimation,
				CORNER_STYLES[corner],
				QUOTE_STYLES[variant]
			)}
			onClick={nextQuote}
			style={style}>
			<span className={styles.close} onClick={toggleSocialProof}>
				X
			</span>
			{episode_name && (
				<i>
					<b>Retour sur l'épisode "{manageLineBreaks(episode_name)}" :</b>
				</i>
			)}
			<br />
			<Markdown>{manageLineBreaks(feedback)}</Markdown>
			<br />
			<div className={csl(styles.Cursive)}>
				{firstName ? firstName : 'Un·e abonné·e'}
			</div>
		</div>
	);
};

export const QuoteCollection = ({
	children,
	bubbleColorChoice = 'random',
	alternateCorners = 'round-robin',
	animationTimeInMs = 10000,
	className = [],
	animationEnabled = true,
	style,
}) => {
	const [quotes, setQuotes] = useState(null);
	const [selectedQuote, setSelectedQuote] = useState(0);
	const quoteCollectionRef = useRef();
	const quoteCollectionIsVisible = useIsVisible(quoteCollectionRef, false);
	const comfortableReadingCharsPerSecond = 18;
	const calcAnimationTimer = (string) => {
		return (string.length / comfortableReadingCharsPerSecond) * 900;
	};
	useEffect(() => {
		if (!children) return;
		let lastVariant = null;

		setQuotes(
			children
				.sort(() => (Math.random() > 0.5 ? 1 : -1))
				.sort(() => (Math.random() > 0.5 ? 1 : -1))
				.map((child, index) => {
					let newVariant = lastVariant;
					// Avoid same color bubble twice in a row
					while (newVariant === lastVariant) {
						newVariant = getIntegerFromRangeWithMethod(
							bubbleColorChoice,
							QUOTE_STYLES.length,
							index
						);
					}
					lastVariant = newVariant;
					child.variant = newVariant;
					child.corner = getIntegerFromRangeWithMethod(
						alternateCorners,
						CORNER_STYLES.length,
						index
					);
					console.log(127, index, children.length);
					return (
						<Quote
							quoteIndex={index}
							numQuotes={children.length}
							key={child.feedback}
							{...child}
							info={child}
							className={csl(styles.Quote, animationEnabled && styles.Sliding)}
							animationEnabled={animationEnabled}
							style={{
								animationDuration:
									calcAnimationTimer(child.feedback) / 1000 + 's',
							}}
						/>
					);
				})
		);
	}, [
		alternateCorners,
		bubbleColorChoice,
		children,
		animationTimeInMs,
		animationEnabled,
	]);

	useEffect(() => {
		if (!quotes || !quotes[selectedQuote]) return;

		const ticker = setInterval(() => {
			// console.log(
			// 	'next quotes length is ',
			// 	children[selectedQuote].feedback.length
			// );
			if (!animationEnabled) clearInterval(ticker);
			setSelectedQuote(
				selectedQuote === quotes.length - 1 ? 0 : selectedQuote + 1
			); // restart from beginning if we're are the last element
		}, calcAnimationTimer(children[selectedQuote].feedback));
		return () => {
			console.log('clearing interval!');
			clearInterval(ticker);
		};
	}, [selectedQuote, quotes, children, animationTimeInMs, animationEnabled]);

	return (
		<div
			ref={quoteCollectionRef}
			className={csl(...className, styles.QuoteCollection)}
			style={{ ...style }}>
			{quoteCollectionIsVisible && quotes && quotes[selectedQuote]}
		</div>
	);
};

export const QuotePopup = ({
	children,
	bubbleColorChoice = 'random',
	alternateCorners = 'round-robin',
	animationTimeInMs = 900,
	animationEnabled = true,
	className = [],
	style,
}) => {
	const [quotes, setQuotes] = useState(null);
	const [selectedQuote, setSelectedQuote] = useState(0);
	const quotePopupRef = useRef();

	const calcAnimationTimer = useCallback(
		(string) => {
			const comfortableReadingCharsPerSecond = 18;
			const suggestedReadingTime =
				(string.length / comfortableReadingCharsPerSecond) * animationTimeInMs;
			return Math.max(suggestedReadingTime, 5000);
		},
		[animationTimeInMs]
	);
	// Build Quotes
	useEffect(() => {
		if (!children) return;
		let lastVariant = null;

		setQuotes(
			children
				.sort(() => (Math.random() > 0.5 ? 1 : -1))
				.sort(() => (Math.random() > 0.5 ? 1 : -1))
				.map((child, index) => {
					let newVariant = lastVariant;
					// Avoid same color bubble twice in a row
					while (newVariant === lastVariant) {
						newVariant = getIntegerFromRangeWithMethod(
							bubbleColorChoice,
							QUOTE_STYLES.length,
							index
						);
					}
					lastVariant = newVariant;
					child.variant = newVariant;
					child.corner = getIntegerFromRangeWithMethod(
						alternateCorners,
						CORNER_STYLES.length,
						index
					);

					return (
						<Quote
							quoteIndex={index}
							numQuotes={children.length}
							key={child.feedback}
							{...child}
							info={child}
							animationEnabled={animationEnabled}
							nextQuote={() =>
								setSelectedQuote(loopThroughArray(quotes, selectedQuote))
							}
							style={{
								animationDuration:
									calcAnimationTimer(child.feedback) / 1000 + 's',
							}}
							toggleSocialProof={toggleSocialProof}
						/>
					);
				})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		alternateCorners,
		bubbleColorChoice,
		children,
		animationTimeInMs,
		selectedQuote,
		animationEnabled,
		calcAnimationTimer,
	]);
	// disable

	// Set timer when quote renders
	useEffect(() => {
		const ticker = setInterval(() => {
			setSelectedQuote(loopThroughArray(quotes, selectedQuote));
		}, calcAnimationTimer(children[selectedQuote].feedback));
		if (!animationEnabled) return clearInterval(ticker);

		return () => clearInterval(ticker);
	}, [
		selectedQuote,
		quotes,
		children,
		animationTimeInMs,
		animationEnabled,
		calcAnimationTimer,
	]);

	let [userDisabledTestimonials, setUserDisabledTestimonials] = useState(
		localStorage.getItem('disableTestimonials') === 'true'
	);
	function toggleSocialProof() {
		let currentState = localStorage.getItem('disableTestimonials') === 'true';
		localStorage.setItem('disableTestimonials', !currentState);
		setUserDisabledTestimonials(!currentState);
	}

	return (
		<div
			ref={quotePopupRef}
			className={csl(...className, styles.QuotePopupContainer)}
			style={{ ...style }}>
			{userDisabledTestimonials && quotes ? quotes[selectedQuote] : null}
			<h2
				onClick={toggleSocialProof}
				style={{
					display: 'inline',
					opacity: userDisabledTestimonials ? 1 : 0.5,
				}}>
				Nos utilisateurs en témoignent !
			</h2>
		</div>
	);
};
