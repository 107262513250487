export function getIntegerFromRangeWithMethod(method = 'default', range, seed) {
	const methods = {
		random: parseInt(Math.random() * range),
		'round-robin': seed % range,
		default: seed,
	};
	let result = methods[method];
	return result;
}

export const b64encodeJsonObject = (object) => {
	return btoa(JSON.stringify(object));
};

export const smoothScrollTo = (targetedElement) => {
	const target = document.getElementById(targetedElement);
	if (!target)
		return console.warn('Could not find element with ID', targetedElement);
	const topPosition = target.offsetTop;
	const leftPosition = target.offsetLeft;

	window.scrollTo({
		top: topPosition,
		left: leftPosition,
		behavior: 'smooth',
	});

	return null;
};

// Find 1-3 contiguous non-whitespace characters (letters, including accented/unicode characters) that are preceded and followed by a space|newline
export function manageLineBreaks(str) {
	str = dontBreakOnSmallWords(str);
	str = makeHyphensNonBreak(str);
	str = makeFrenchPunctuationNonBreak(str);
	return str;
}
const NBSP = '\u00A0';
// Rules:
// Add a non-break space BEFORE words with 3 letters or less
// UNLESS it follows a punctuation mark (i.e. A short word is starting a new sentence)
export const dontBreakOnSmallWords = (string) =>
	string.replace(/\s(\b\w{1,2}\b(?=\W|\s))/g, '\u00A0$1');

export const makeHyphensNonBreak = (string) =>
	string.replace(/\u2010|-/gm, '\u2011');

export const makeFrenchPunctuationNonBreak = (string) => {
	const dontBreak = [/« /gm, / »/gm, / :/gm, / ;/gm, / \?/gm, / !/gm];
	return dontBreak.reduce(
		(string, character) =>
			string.replace(character, (match) => match.replace(' ', NBSP)),
		string
	);
};
