import React from 'react';
import styles from './Price.module.scss';
import { FadeIn } from 'components';

export const TEXT_STYLES = [
	styles.ItalicLight,
	styles.GradientTextCursiveQuarternary,
];

export const Price = ({
	content,
	delayBetweenQuotesInSeconds = 2,
	fadeInTime = 1500,
	className = [],
	style,
}) => {
	return (
		<React.Fragment>
			<FadeIn
				style={{ display: 'flex', flexDirection: 'inherit', width: '100%' }}
				duration={fadeInTime}
				delayInSeconds="0">
				<div className={styles.Price}>
					<span className={styles.GradientTextCursiveQuarternary}>
						{content}
					</span>
				</div>
			</FadeIn>
		</React.Fragment>
	);
};
