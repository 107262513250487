import React, { useEffect, useState } from 'react';
import { WhitePaperForm } from 'components/Form-WhitePaper';
import styles from './WhitePaper.module.scss';
import btnStyles from '../components/BtnCta.module.scss';
import { useAnalytics } from 'utils/useAnalytics.js';
import { posthog } from 'posthog-js';

export const WhitePaper = ({ isVertical }) => {
	// const downloadUrl = new URLSearchParams(window.location.search).get("downloadUrl");
	const searchParams = new URLSearchParams(window.location.search);
	const hasDownloadParams = searchParams.has('id') && searchParams.has('token');
	const [downloadUrl, setDownloadUrl] = useState(false);

	const analytics = useAnalytics();
	useEffect(() => {
		analytics.capture(analytics.events.livre_blanc.clicked);
	}, [analytics]);

	useEffect(() => {
		if (hasDownloadParams) {
			analytics.capture(analytics.events.livre_blanc.downloaded);
			posthog.capture(analytics.events.livre_blanc.downloaded.name);
			fetch(`/api/whitepaper-download${window.location.search}`)
				.then((body) => body.text())
				.then((resp) => setDownloadUrl(resp));
			setCookie('LB_AUTH', 1, 21);
		}
	}, [analytics, hasDownloadParams]);

	return (
		<section className={styles.FormPageParent}>
			{hasDownloadParams ? (
				<>
					<h1>Nous y sommes presque...</h1>
					<h4 className={styles.DescriptionText}>
						Parce que <b>l'engagement</b> de vos collaborateurs,{' '}
						<b>l'énergie</b> de vos équipes et leur <b>développement</b> sont
						une <b>priorité</b>.{' '}
					</h4>
					<br />
					{downloadUrl ? (
						<a
							href={downloadUrl + window.location.search}
							data-name={'DOWNLOAD_WHITEPAPER'}
							download="Livre Blanc - GOOD Vibes.pdf"
							filename="Livre Blanc - GOOD Vibes.pdf"
							className={btnStyles.BtnCta}>
							Telecharger le livre blanc !
						</a>
					) : (
						<h3>Loading...</h3>
					)}
				</>
			) : (
				<>
					<h3>RECEVOIR NOS LIVRES BLANCS!</h3>
					<h4 className={styles.DescriptionText}>
						Recevez gratuitement nos livres blancs dans votre boîte mail en
						remplissant le formulaire.
						<br />
						<br />
						Bonne lecture !
					</h4>
					<WhitePaperForm isVertical />
				</>
			)}
		</section>
	);
};

function setCookie(name, value, days) {
	var expires = '';
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = '; expires=' + date.toUTCString();
	}
	document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
// function getCookie(name) {
//     var nameEQ = name + "=";
//     var ca = document.cookie.split(';');
//     for(var i=0;i < ca.length;i++) {
//         var c = ca[i];
//         while (c.charAt(0)==' ') c = c.substring(1,c.length);
//         if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
//     }
//     return null;
// }
// function eraseCookie(name) {
//     document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
// }
