import React from 'react';
import styles from './Background.module.scss';

const csl = (...classes) => classes.filter(Boolean).join(" ");

export const Background = ({
    style,
    className,
    children,
    variant
}) => {
    return (
        <div 
        style={style}
        className={csl(
            className,
            styles.Background,
            // styles.Parallax,
            // styles.ParallaxLayer,
            // styles.ParallaxBack,
            // styles.ParallaxBase,
            variant === "dark" && styles.DarkBackground,
            )
        }>
            {/* {React.Children.map(
                children, (child,index) => {
                    let rightOrLeft = getIntegerFromRangeWithMethod("round-robin",children.length,index)%2 ? "right" : "left";
                    let size = [styles.Big, styles.Medium, styles.Small][getIntegerFromRangeWithMethod("random",3,index)]
                    let style = {};
                    style[rightOrLeft] = 0;
                    style.top = 20*index + "%"; 
                    
                    return variant === "dark" && <div 
                    style={style}
                    className={csl(styles.Circle, styles["Color"+index], size)} />
                })
            } */}
            {children}
        </div>
    );
}
