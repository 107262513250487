import React from 'react';
import styles from './ScreenCast.module.scss';
// import Markdown from 'markdown-to-jsx';

// const description = 
// `Notre plateforme est vraiment super. Elle permet de faire plein de trucs, notamment : 
// <ul>
// - Gérer la file d'attente
// - Visionner les épisodes
// - Suivre les statistiques d'utilisation
// </ul>`

export const ScreenCast = () => {


	return (
		<section className={styles.ScreenCastParent}>
			<h3>Découvrir la plateforme GOOD Vibes</h3>
			{/* Markdown won't compile if html elements are inside of it, unless you wrap it all in backticks, this seems to be a known issue
			https://github.com/mathieudutour/medium-to-own-blog/commit/0c5eb3bad8a747e7a2c03837e85d65b220bb50f2 */}
			<iframe 
				title="screencast" type="text/javascript" 
				src='https://cdnapisec.kaltura.com/p/2508431/embedPlaykitJs/uiconf_id/48733743?iframeembed=true&entry_id=1_00bmk109' 
				style={{width: "100%", height: "50vh", margin: "0 auto"}} 
				allowfullscreen webkitallowfullscreen="true" mozallowfullscreen="true" 
				allow="autoplay *; fullscreen *; encrypted-media *" 
				frameBorder="0">
			</iframe>
			<br></br>
			{/* <Markdown className={styles.Content}> */}
				{/* {description} */}
			{/* </Markdown> */}

		</section>
	)
}
