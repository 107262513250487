import React from 'react';
import styles from './Block.module.scss';
import { StickyHeader } from 'utils/StickyHeader';

const csl = (...classes) => classes.filter(Boolean).join(' ');

const BlockComponent = ({
	className = null,
	children,
	blockTitle,
	marginTopProp,
	...props
}, ref) => {
	return (
		<div
			{...props}
			ref={ref}
			className={csl(className, styles.FlexColumnContainer, styles.Block)}
		>
			{blockTitle && 
			<StickyHeader className={styles.BlockTitle} marginTopProp={marginTopProp}>
				{blockTitle && <h3>{blockTitle}</h3>}
			</StickyHeader>}
			{children}
		</div>
	);
};

export const Block = React.forwardRef(BlockComponent);