import React, { useState } from 'react';
import styles from './Episodes.module.scss';
import Markdown from 'markdown-to-jsx';
import {
	b64encodeJsonObject,
	smoothScrollTo,
} from './../utils/utilityFunctions';

// IMAGES
import logoAnim from 'static/img/logo-gv.png';
import playButton from 'static/img/play-button.svg';
import dodeRenard from './../static/img/episodes/dode-renard.png';
import thibaultDeschamps from './../static/img/episodes/thibault-deschamps.png';
import dominiqueBellos from './../static/img/episodes/dominique-bellos.png';
import christopheDeval from './../static/img/episodes/christophe-deval.png';

export const Episodes = () => {
	return (
		<section className={styles.Section}>
			<h1>Épisodes GOOD Vibes</h1>
			<div className={styles.Header}>
				<img src={logoAnim} alt="GOOD-Vibes-logo" className={styles.Logo} />
				<span className={styles.TitleContainer}>
					<Markdown className={styles.Subtitle}>
						Nos experts et expertes sont des __sociologues, philosophes,
						consultants, docteures__ en neurosciences, __psychologues, artistes,
						professeurs de médecine, nutritionnistes, entrepreneuses,
						kinésithérapeutes, universitaires, biologistes, sportives__ de haut
						niveau, __DRH, aventuriers__... toutes et tous choisis avec soin
						pour leur légitimité, l'authenticité et la simplicité avec laquelle
						ils et elles partagent leur regard et bien sûr leurs
						GOOD&nbsp;Vibes&nbsp;!
					</Markdown>
					<legend>
						Toute diffusion et copie sans autorisation est interdite.
					</legend>
				</span>
			</div>

			<PlayerAndEpisodes />

			<h3>+ de 60 cycles</h3>
			<div className={styles.Description}>
				1 cycle = 1 regroupement d'épisodes pour approfondir un même sujet.
				<br />
				Durée : 1 mois (4 vidéos en moyenne)
			</div>

			<CyclesCollectif />

			<CyclesIndividu />
		</section>
	);
};

export const PlayerAndEpisodes = ({
	language = 'FR',
	currentlyPlaying = 0,
}) => {
	const episodesToDisplay = [
		{
			GVID: 'GV211115',
			episode_name_EN: 'Giving recognition',
			episode_name_FR: 'Donner de la reconnaissance',
			episode_theme_EN:
				'The importance for managers to give signs of recognition, their role in team dynamics.',
			episode_theme_FR:
				'L’importance pour le ou la manager de donner des signes de reconnaissance, son rôle dans la dynamique d’une équipe.',
			episode_hook_EN: "Recognition: it's free and it reaps huge benefits",
			episode_hook_FR:
				'La reconnaissance, un acte gratuit qui apporte beaucoup.',
			good_story_interviewee: 'Dode Renard',
			interviewee_title_FR: 'Executive Coach',
			interviewee_title_EN: 'Executive Coach',
			episode_opengraph_thumbnail: dodeRenard,
			episode_ID_EN: '1_qroz7n4k',
			episode_ID_FR: '1_ne76ufgr',
		},
		{
			GVID: 'GV211018',
			episode_name_EN: 'Finding Fitness',
			episode_name_FR: 'En forme !',
			episode_theme_EN: 'A healthy mind lives in a healthy body',
			episode_theme_FR:
				'Être mieux dans ses baskets pour être mieux avec les autres.',
			episode_hook_EN: "Let's get fit!",
			episode_hook_FR: 'A fond la forme !',
			good_story_interviewee: 'Thibault Deschamps',
			interviewee_title_FR:
				'Conseiller Technique Sportif Athlétisme - Ministère des Sports',
			interviewee_title_EN:
				'Athletics Sports Technical Advisor - Ministry of Sports',
			episode_opengraph_thumbnail: thibaultDeschamps,
			episode_ID_EN: '1_j06l4klj',
			episode_ID_FR: '1_8cpkdvkp',
		},
		{
			GVID: 'GV210709',
			episode_name_EN: 'Driver: Enjoyment',
			episode_name_FR: 'Plaisir Moteur',
			episode_theme_EN: 'Pleasure as a driver for success.',
			episode_theme_FR: 'Le plaisir, moteur de la réussite.',
			episode_hook_EN: 'We work better when we love what we do.',
			episode_hook_FR: 'Quand on aime, on réussit mieux !',
			good_story_interviewee: 'Dominique Bellos',
			interviewee_title_FR: 'Ancienne DRH Hutchinson, Consultante',
			interviewee_title_EN: 'Consultant and former SVP of HR at Hutchinson SA',
			episode_opengraph_thumbnail: dominiqueBellos,
			episode_ID_EN: '1_wpey0dy4',
			episode_ID_FR: '1_jk03e6li',
		},
		{
			GVID: 'GV220124',
			episode_name_EN: 'Mastery vs. Control',
			episode_name_FR: 'Maîtrise / Contrôle',
			episode_theme_EN:
				'Let’s focus on what we can control, rather than on what’s out of our control.',
			episode_theme_FR:
				'Plutôt que d’essayer de contrôler l’incontrôlable, concentrons-nous sur ce que l’on maîtrise.',
			episode_hook_EN: "It's time to let go!",
			episode_hook_FR: 'Il est temps de lâcher !',
			good_story_interviewee: 'Christophe Deval',
			interviewee_title_FR: 'Psychologue, coach, associé fondateur A-Life',
			interviewee_title_EN: 'Psychologist, coach, founding partner A-Life',
			episode_opengraph_thumbnail: christopheDeval,
			episode_ID_EN: '1_ngw9qw43',
			episode_ID_FR: '1_80s7jzvy',
		},
	];

	const getPlayerConfig = () => {
		const playerConfig = b64encodeJsonObject({
			show_header: false,
			show_footer: false,
			show_clap: true,
			show_unsub: false,
			show_preferences: false,
			show_landing: false,
			show_apl: false,
			show_fullscreen: true,
		});
		return playerConfig;
	};

	const makeURL = (GVID, language = 'FR') => {
		const sessionObj = b64encodeJsonObject({
			videoID: `${GVID}${language}`,
			userID: -1,
			companyID: 6, // "Prospects"
			log_click: 0,
			disable_analytics: 1,
		});

		const playerConfig = getPlayerConfig();
		const playerURL = `${process.env.REACT_APP_PLAYER_URL_BASE}/embed/${sessionObj}?config=${playerConfig}`;
		return playerURL;
	};

	const [videoCurrentlyPlaying, setVideoCurrentlyPlaying] =
		useState(currentlyPlaying);

	return (
		<div className={styles.PlayerAndEpisodes}>
			<span
				className={styles.VideoPlayerContainer}
				style={{ backgroundColor: 'transparent' }}>
				<iframe
					className={styles.VideoPlayer}
					title="kaltura-player"
					id="player"
					type="text/javascript"
					src={makeURL(episodesToDisplay[videoCurrentlyPlaying].GVID, language)}
					allowfullscreen
					webkitallowfullscreen="true"
					mozallowfullscreen="true"
					allow="autoplay *; fullscreen *; encrypted-media *"
					frameBorder="0"
					style={{ backgroundColor: 'transparent' }}></iframe>
			</span>

			<div className={styles.EpisodesContainer}>
				{episodesToDisplay.map((episode) => {
					return (
						<div
							className={styles.EmbeddedEpisode}
							onClick={() => {
								setVideoCurrentlyPlaying(episodesToDisplay.indexOf(episode));
								smoothScrollTo('player');
							}}>
							<span className={styles.ThumbnailContainer}>
								<img
									className={styles.Thumbnail}
									src={episode.episode_opengraph_thumbnail}
									alt={`${episode.episode_name_FR} thumbnail`}
								/>
								<img
									className={styles.PlayButton}
									src={playButton}
									alt="play-button"
								/>
							</span>

							<span className={styles.VideoInfo}>
								<span className={styles.VideoName}>
									{episode.episode_name_FR}
								</span>
								<span className={styles.Interviewee}>
									Avec {episode.good_story_interviewee},{' '}
									{episode.interviewee_title_FR}
								</span>
								<span className={styles.VideoTheme}>
									{episode.episode_theme_FR}
								</span>
							</span>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export const CyclesCollectif = () => {
	const cycles = [
		{
			cycleTitle: 'Manager',
			nbOfCycles: 4,
		},
		{
			cycleTitle: 'Santé ',
			nbOfCycles: 3,
		},
		{
			cycleTitle: 'Travailler mieux ',
			nbOfCycles: 3,
		},
		{
			cycleTitle: 'Diriger',
			nbOfCycles: 2,
		},
		{
			cycleTitle: 'Résoudre les problèmes',
			nbOfCycles: 3,
		},
		{
			cycleTitle: 'Valoriser les talents',
			nbOfCycles: 3,
		},
		{
			cycleTitle: 'Innover',
			nbOfCycles: 4,
		},
		{
			cycleTitle: 'RSE',
			nbOfCycles: 4,
		},
		{
			cycleTitle: 'QVT / Vivre mieux',
			nbOfCycles: 4,
		},
		{
			cycleTitle: 'Se réaliser',
			nbOfCycles: 4,
		},
		{
			cycleTitle: 'Changer',
			nbOfCycles: 3,
		},
		{
			cycleTitle: 'S’inspirer',
			nbOfCycles: 1,
		},
	];

	return (
		<div>
			<h3>Cycles longs</h3>
			<span className={styles.CyclesCollectifContainer}>
				{cycles.map((cycle) => {
					return (
						<span className={styles.OneCycleCollectifContainer}>
							<span className={styles.CycleCollectifTitle}>
								{cycle.cycleTitle}
							</span>
							<span className={styles.CycleCollectifNbCycles}>
								{cycle.nbOfCycles} {cycle.nbOfCycles === 1 && 'cycle'}
								{cycle.nbOfCycles > 1 && 'cycles'}
							</span>
						</span>
					);
				})}
			</span>
		</div>
	);
};
export const CyclesIndividu = () => {
	const cycles = [
		'Soft Skills',
		'Feedback',
		'Émotions',
		'Le mental',
		'Reconnaissance',
		'Assertivité',
		'La Voix',
		'Intelligence collective',
		'Intuition',
		'Créativité',
		'Le conflit',
		'Transformation',
		'RSE',
		'En forme',
		'RH',
		'Prise de décision',
		'Inspiration',
		'Cerveau',
		'Forces & Faiblesses',
		'Conflit',
		'Joie de vivre',
		'Good Entreprise',
	];

	return (
		<div>
			<h3>Cycles courts</h3>
			<div className={styles.CyclesIndividuContainer}>
				{cycles.map((cycle) => {
					let isNotLastCycle = cycles.indexOf(cycle) !== cycles.length - 1;
					if (isNotLastCycle) {
						return (
							<>
								<span className={styles.OneCycleIndividu}>{cycle}</span>
								<span className={styles.Slash}> / </span>
							</>
						);
					} else
						return <span className={styles.OneCycleIndividu}>{cycle}</span>; // add a slash after each cycle except the last one
				})}
			</div>
		</div>
	);
};
