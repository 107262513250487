import React from 'react';
import styles from './ConsentModal.module.scss';
import Markdown from 'markdown-to-jsx';

const csl = (...classes) => classes.filter(Boolean).join(' ');

const ConsentModalComponent = (
	{ className = null, children, title, text, ...props },
	ref
) => {
	return (
		<div
			{...props}
			ref={ref}
			className={csl(
				className,
				styles.FlexColumnContainer,
				styles.ConsentModal
			)}>
			{title ? <h2>{title}</h2> : null}
			<Markdown>{text}</Markdown>
			<div className={styles.buttons}>{children}</div>
			<span>Merci de votre confiance !</span>
		</div>
	);
};

export const ConsentModal = React.forwardRef(ConsentModalComponent);
