import React, { useState, useEffect, useRef } from 'react';
import styles from './Forms.module.scss';
import { BtnCta, FadeIn } from 'components';
// import { useTranslation } from "react-i18next";
// import { useFetch } from "../../utils/fetchHook";
import { useForm } from 'react-hook-form';
import Lottie from 'react-lottie-player';

import * as ANIM_LOADING from 'static/anim/71460-squared-circles-red.json';
// import * as ANIM_ALERT from "static/anim/lf20_ZGnXlB.json";
import * as ANIM_SMS from 'static/anim/37923-sms-notification.json';
import * as ANIM_ERROR from 'static/anim/28815-errorwarning.json';
import { useAnalytics } from 'utils/useAnalytics.js';
import { posthog } from 'posthog-js';

const csl = (...classes) => classes.filter(Boolean).join(' ');

const options = {
	method: 'POST',
	headers: { 'Content-Type': 'application/json' },
};

/* If we want to hide the form and only display it onClick, just change displayForm to false in the line below (TryNowForm props) */
export const WhitePaperForm = ({ displayForm = true, isVertical = false }) => {
	// Toggle formState on click
	// false shows the CTA, true or 'shown' shows the form, 'submitted' is for after the form is submitted.
	const [formStatus, setFormStatus] = useState(displayForm);
	const [formIsReadyToSubmit, setFormIsReadyToSubmit] = useState(displayForm);

	useEffect(() => {
		setFormStatus(displayForm);
	}, [displayForm]);

	if (formStatus === 'submitted') window.navigator.vibrate(1000);

	// Setup form value handling
	// https://react-hook-form.com/api/useform
	const {
		register,
		handleSubmit,
		watch,
		setError,
		clearErrors,
		trigger,
		formState: { isValid, errors, isSubmitting },
	} = useForm({ mode: 'onBlur' });
	const watchTelephone = watch('tel_E164', false);
	const telTimeout = useRef();

	const analytics = useAnalytics();
	useEffect(() => {
		analytics.capture(analytics.events.livre_blanc.clicked);
		posthog.capture(analytics.events.livre_blanc.clicked.name);
	}, [analytics]);

	useEffect(() => {
		setFormIsReadyToSubmit(!isValid || !!errors.tel_E164);
	}, [errors, isValid]);

	useEffect(() => {
		// Wait until minimum telephone length before running validation via API
		if (watchTelephone && watchTelephone.length > 7) {
			clearTimeout(telTimeout.current);
			telTimeout.current = setTimeout(() => {
				validateTelNum(watchTelephone)
					.then((message) => {
						clearErrors('tel_E164');
						trigger();
					})
					.catch((message) => {
						console.warn(message);
						setError('tel_E164', { message });
					});
			}, 500);
		} else {
			// Clear errors if they fix the number/reset the field
			clearErrors('tel_E164');
		}
	}, [clearErrors, setError, telTimeout, trigger, watchTelephone]);

	// Setup form submission
	const onSubmit = async (data, second) => {
		console.log('submitting!');
		console.log(data, second);
		await fetch(`${process.env.REACT_APP_API_URL_BASE}/whitepaper-submit`, {
			...options,
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((response) => {
				if (response.status === 'KO') setFormStatus('error');
				if (response.status === 'OK') setFormStatus('success');
				analytics.capture(analytics.events.livre_blanc.submitted);
				console.log(response);
				if (process.env.REACT_APP_DOWNLOAD_WP_AFTER_FORM_SUBMIT === 'true')
					window.open(response.whitePaperDownloadUrl, '_self');
				console.log('FORM IS: ', formStatus);
			});
	};

	// const { t } = useTranslation();

	return (
		<>
			{!formStatus && (
				<FadeIn delayInSeconds={2}>
					<BtnCta
						onClick={() => setFormStatus('shown')}
						variant="primary"
						content="Envoyez-moi le livre blanc !"
					/>
				</FadeIn>
			)}
			{(formStatus === true || formStatus === 'shown') && (
				<FadeIn>
					<form onSubmit={handleSubmit(onSubmit)} className={styles.FormBody}>
						<div className={isVertical ? styles.VerticalInputs : styles.Inputs}>
							<input
								{...register('fName', { required: 'Champ obligatoire' })}
								className={csl(styles.FormField, errors.fName && styles.Error)}
								name={'fName'}
								type={'text'}
								placeholder={'Marie'}
								required
							/>
							{errors.fName && (
								<span className={styles.ErrorMessage}>
									{errors.fName.message}
								</span>
							)}
							<input
								{...register('lName', { required: 'Champ obligatoire' })}
								className={csl(styles.FormField, errors.lName && styles.Error)}
								name={'lName'}
								type={'text'}
								placeholder={'Sklodowska'}
								required
							/>
							{errors.lName && (
								<span className={styles.ErrorMessage}>
									{errors.lName.message}
								</span>
							)}
							<input
								{...register('email', {
									required: 'Champ obligatoire',
									minLength: {
										value: 5,
										message: 'Cette adresse est trop courte !',
									},
								})}
								className={csl(styles.FormField, errors.email && styles.Error)}
								name={'email'}
								type={'email'}
								placeholder={'marie@curie.fr'}
								required
							/>
							{errors.email && (
								<span className={styles.ErrorMessage}>
									{errors.email.message}
								</span>
							)}
							<input
								// ref={phoneFieldRef}
								{...register('tel_E164', {
									required: 'Champ obligatoire',
									validation: validateTelNum,
								})}
								className={csl(
									styles.FormField,
									styles.FormInputValid,
									errors.tel_E164 && styles.Error
								)}
								name={'tel_E164'}
								type={'tel'}
								placeholder={'+33 6 18 67 19 34'}
								required
							/>
							{errors.tel_E164 && (
								<span className={styles.ErrorMessage}>
									{errors.tel_E164.message}
								</span>
							)}
							{/* {
                      errors && Object.values(errors).map(error => <FadeIn className={styles.ErrorMessage}><Lottie play loop animationData={ANIM_ALERT} style={{height: 50, width: 50, margin: "0 auto"}} />&nbsp;&nbsp;{error.message}</FadeIn>)
                    } */}
							<input
								{...register('fonction', {
									required: 'Champ obligatoire',
									minLength: {
										value: 5,
										message: 'Veuillez nous indiquer votre poste !',
									},
								})}
								className={csl(
									styles.FormField,
									errors.fonction && styles.Error
								)}
								name={'fonction'}
								type={'fonction'}
								placeholder={'Directeur de bonnes ondes'}
								required
							/>
							{errors.fonction && (
								<span className={styles.ErrorMessage}>
									{errors.fonction.message}
								</span>
							)}
							<input
								className={csl(
									styles.BtnCta,
									styles.SubmitButton,
									formIsReadyToSubmit ? styles.Disabled : ''
								)}
								type="submit"
								value={'Envoyez-moi le livre blanc !'}
							/>
							{isSubmitting && (
								<Lottie
									loop
									play
									animationData={ANIM_LOADING}
									style={{ height: '50px', width: '50px' }}></Lottie>
							)}
							{/* <BtnCta type={"submit"} content="Submit" variant="primary" /> */}
						</div>
						<p className={styles.DescriptionText}>
							GOOD Vibes s'engage à ne jamais transmettre vos informations
							personnelles à des tiers.
						</p>
					</form>
				</FadeIn>
			)}
			{(formStatus === 'success' || displayForm === 'submitted') && (
				<>
					<Lottie
						play
						loop
						animationData={ANIM_SMS}
						style={{ height: '100px' }}
					/>
					<p className={styles.DescriptionText}>
						Surveillez votre boite mail, le livre blanc GOOD Vibes arrive !
					</p>
				</>
			)}
			{(formStatus === 'error' || displayForm === 'error') && (
				<>
					<Lottie
						play
						loop
						animationData={ANIM_ERROR}
						style={{ height: '200px' }}
					/>
					<p>
						Oh non ! Une erreur est survenue. Notre équipe a été notifiée
						automatiquement. <br />
						Réitérez votre demande ultérieurement, ou{' '}
						<a href={`mailto:${process.env.REACT_APP_contact}`}>
							contactez-nous par e-mail.
						</a>
					</p>
				</>
			)}
		</>
	);
};

var messages = {
	landline: `Vous ne pouvez pas utiliser un numéro ☎️ fixe !`,
	voip: `Vous ne pouvez pas utiliser un numéro de téléphone VoIP !`,
	mobile: `Merci, votre numéro est 👍 📲  !`,
	invalid: `Ce numéro n'est pas valide 📵 ! Essayez avec le préfixe international (ex. +33 6 11 22 33 44)`,
	duplicate: `Vous avez déjà fait un essai gratuit, ou bien vous êtes déjà abonné·e 📵 !`,
};

function validateTelNum(phone_number, countryCode_iso2 = 'fr') {
	console.log(
		`Looking up Phone Number: ${phone_number} with country code ${countryCode_iso2}`
	);

	const validationUrl = `${process.env.REACT_APP_API_URL_BASE}/validatePhoneNumber?telephone=${phone_number}`; // + "&CountryCode=" + countryCode_iso2;
	return new Promise((resolve, reject) => {
		fetch(validationUrl)
			.then((response) => response.json())
			.then(({ type }) => {
				switch (String(type)) {
					// Using "String() on the payload so I can case compare with undefined without throwing an error
					case 'mobile':
						// CASE VALID NUMBER
						resolve(messages[type]);
						break;
					case 'landline':
					case 'voip':
					case 'duplicate':
						// Allow people to download whitepaper even if they are already subscribed to GV
						resolve(messages[type]);
						break;
					case 'null':
					case 'undefined':
					default:
						// CASE INVALID NUMBER
						// CASE LANDLINE
						reject(messages['invalid']);
						break;
				}
			});
	});
}
