import React from 'react';
import styles from './TextOnLogoShape.module.scss';


// This component will display logoText prop, which by default is "Ça charge !", to avoid repetition throughout components and pages
export const TextOnLogoShape = ({ logoText = "Ça charge !" }) => {
	return (
		<section className={styles.Parent}>
			<span className={styles.Shape}>
				<h3>{logoText}</h3>
			</span>
		</section>
	);
};
